html {
  height: 100vh;
}
body {
  padding: unset !important;
}
/* main {
  width: 100%;
  text-align: center;
  display: flex;
  height: calc(100vh - 118px);
  overflow: auto;
} */
.main-container {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  justify-content: center;
}
.session-container {
  width: 60%;
  margin: 0 auto;
}
.course-content-container {
  /* width: 40%; */
  text-align: left;
  /* margin-top: 30px; */
  padding: 1rem;
}
main #join-flow button {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

main #join-flow button:hover {
  background-color: #2681f2;
}
.header {
  width: 100%;
  height: 80px;
  gap: 0px;
  background-color: black;
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 0 8%;
}

.header-text {
  width: 100%;
  height: 20px;
  top: 30px;
  left: 306.14px;
  gap: 0px;
  /* opacity: 0; */
  color: rgba(255, 255, 255, 1) !important;
}
.vertical-line-header {
  width: 40px;
  height: 0px;
  gap: 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  rotate: 90deg;
  border: 1px solid rgba(228, 232, 235, 1);
}
.course-content-title {
  font-size: 24px;
  font-weight: 600;
  margin-left: 30px;
}
.accordian-container {
  height: 62px;
}
.accordian-container-parent {
  margin-top: 30px;
}
.accordian-li {
  height: 46px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accordian-li:hover {
  background: rgba(245, 245, 245, 1);
}
.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  background: rgba(121, 80, 226, 0.1);
  color: rgba(121, 80, 226, 1) !important;
}

.footer-container-custom {
  display: flex;
  gap: 10rem;
}
.width-33 {
  width: 33%;
}
.width-15 {
  width: 15%;
}
.copy-rgt {
  display: flex;
  justify-content: space-between;
}
.diplay-flex-custom {
  display: flex;
  gap: 0.5rem;
}
.join-flow {
  width: 700%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.join-button {
  background-color: rgba(121, 80, 226, 1) !important;
  margin-top: unset !important;
}
.join-flow-title {
  font-size: 32px;
  font-weight: 600;
}
.join-flow-text {
  font-size: 16px;
  font-weight: 400;
}
.click-button {
  position: absolute !important;
  top: 10rem;
  right: 1rem;
  border-color: rgba(121, 80, 226, 1) !important;
  border-radius: 0.5rem !important;
  color: rgba(121, 80, 226, 1) !important;
}
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: unset !important;
}
.dialog-position {
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    position: absolute !important;
    right: 3rem !important;
    top: 12rem !important;
    width: 40rem;
  }
  .css-uhb5lp {
    position: absolute !important;
    right: 3rem !important;
    top: 12rem !important;
    width: 40rem;
  }
}
.info-icon {
  margin-right: 0.5rem !important;
}
/* ***************************************************************** */

@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@400;500;600;700;800;900&family=Sora:wght@400;500;600;700;800&display=swap");

:root {
  --purple-color: #7950e2;
  --purple-color-05: #7950e20d;
  --border-grey-color: #e4e8eb;
  --grey-color: #e3e3e8;
  --primary-text-color: #2d2f31;
  --secondary-text-color: #939393;
  --white-color: #ffffff;
  --black-color: #111111;
  --red-color: #ef2b10;
  --green-color: #0ab730;
  --label-green-color: #30b11d;
  --black-color-00: #000000;
  --black-color-26: #00000026;
  --black-color-1A: #0000001a;
  --black-color-0D: #1018280d;
  --black-color-1F: #1d20261f;
  --black-color-26: #00000026;
  --light-pink-color: #f4e4ff;
  --white-color-F5: #f5f5f5;
  --box-shadow-color: #0f15250f;
  --orange-color: #f9931d;
  --green-color: #30ad25;
  --red-color: #ef2b10;
  --red-color-05: #ef2b100d;
  --purple-color-1A: #7950e21a;
  --purple-color-0D: #7950e20d;
  --red-color-0D: #ef2b100d;
  --green-color-0D: #0ab7300d;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Be Vietnam Pro", sans-serif;
}

.container {
  max-width: 85rem !important;
  margin: 0 auto;
}
.zoom-container .banner-section {
  background-color: var(--white-color-F5);
  padding: 1.25rem;
}
.zoom-container .banner-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.53rem;
  color: var(--primary-text-color);
  margin-bottom: 0;
  position: relative;
}
.zoom-container .banner-title::after {
  position: absolute;
  content: "";
  top: 0;
  left: -1.25rem;
  width: 0.1875rem;
  height: 100%;
  background-color: var(--purple-color);
}
.zoom-container .banner-description {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.8975rem;
  color: var(--secondary-text-color);
}

.page-main-container {
  margin-top: 2rem;
}
.page-main-container .zoom-container {
  display: flex;
}
.page-main-container .session-section {
  margin-bottom: 1.5rem;
}
.page-main-container .session-section .session-section-title {
  color: var(--primary-text-color);
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8975rem;
  margin-bottom: 1.25rem;
}
.page-main-container .session-section .session-box {
  padding: 1.25rem 2.5rem;
  border: 0.0625rem solid var(--grey-color);
}
.session-box .session-title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5813rem;
  color: var(--purple-color);
}
.session-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.session-box ul li {
  display: flex;
  align-items: center;
  margin-top: 1.125rem;
}

.session-box ul li p {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.125rem;
  color: var(--primary-text-color);
}

.session-box .tooltip {
  position: relative;
  display: flex;
  margin-left: 0.1875rem;
}

.session-box .tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--black-color);
  color: var(--white-color);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}

.session-box .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.session-box .tooltip:hover .tooltiptext {
  visibility: visible;
}

.instructor-section .instructor-profile-section {
  margin: 0.625rem 0 0.625rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.instructor-section .instructor-profile-section .instructor-logo {
  width: 7.5rem;
  height: 7.5rem;
  margin-right: 1.25rem;
  border-radius: 50%;
}

.instructor-section .instructor-profile-section .instructor-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-heading {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5813rem;
  color: var(--purple-color);
  text-decoration: none;
  margin-bottom: 0.875rem;
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-heading
  a {
  color: var(--purple-color);
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-list {
  padding-bottom: 0.625rem;
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-list:last-child {
  padding-bottom: 0rem;
}

.session-section
  .instructor-section
  .instructor-profile-section
  .instructor-details
  .icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}
.session-section .instructor-section .instructor-btn {
  display: flex !important;
  justify-content: flex-end !important;
  height: fit-content !important;
}
.session-section .instructor-section .instructor-btn .copy-icon {
  color: var(--primary-text-color) !important;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  border: 1px solid var(--border-grey-color) !important;
  white-space: nowrap;
  padding: 0.75rem 1rem;
  background-color: transparent !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
}
.session-section .instructor-section .instructor-btn .copy-icon:hover {
  background: var(--purple-color-05) !important;
}

.session-section .instructor-description {
  margin: 1.25rem 0 2.5rem;
}

.user-rating-section .user-profile-img {
  width: 4rem;
  height: 4rem;
}

.user-rating-section {
  margin: 3.4375rem 0 2.5rem;
}

.user-rating-section .user-profile-img img {
  width: 100%;
  height: 100%;
}

.user-rating-section .user-name {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5813rem;
  color: var(--primary-text-color);
  margin-bottom: 0.625rem;
}

.user-rating-section .user-message {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4375rem;
  color: var(--primary-text-color);
  margin: 0;
  padding-top: 1.25rem;
}

.user-rating-section .left-section .total-star {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 4.7438rem;
  color: var(--primary-text-color);
}

.user-rating-section .left-section .total-ratings {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.265rem;
  color: var(--black-color);
}

.user-rating-section .left-section {
  padding-right: 1.875rem;
  text-align: center;
}

.user-rating-section .right-section {
  flex: 1;
  border-left: 0.0625rem solid var(--border-grey-color);
  padding-left: 1.875rem;
}

.user-rating-section .right-section ul {
  margin: 0;
  padding: 0;
}

.user-rating-section .right-section .ratings-list {
  margin-bottom: 1.4375rem;
}

.user-rating-section .right-section .ratings-list .border {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--white-color-F5);
}

.user-rating-section .right-section .ratings-list .border .ratings-border {
  background-color: var(--purple-color);
  height: 0.5rem;
  border-radius: 0.25rem;
}

.user-rating-section .right-section .ratings-list .ratings-border {
  background-color: var(--purple-color);
  height: 8px;
  border-radius: 4px;
}

.user-rating-section .right-section .ratings-list .ratings-grey-border {
  width: 100%;
  height: 8px;
  background-color: var(--white-color-F5);
  border-radius: 4px;
}

.align-items-center {
  align-items: center;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.page-main-container .main-left-section {
  width: calc(100% - 26.25rem);
  margin-right: 2.5rem;
}
.page-main-container .main-right-section {
  width: 23.75rem;
}
.page-main-container .main-right-section .session-detail-section {
  padding: 1.25rem;
  border: 0.0625rem solid var(--border-grey-color);
}
.page-main-container
  .main-right-section
  .session-detail-section
  .session-detail-title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  color: var(--primary-text-color);
  margin-bottom: 1.25rem;
}
.page-main-container
  .main-right-section
  .session-detail-section
  .session-detail-list
  .session-detail-item {
  padding-bottom: 0.75rem;
  display: flex;
}
.page-main-container
  .main-right-section
  .session-detail-section
  .session-detail-list
  .session-detail-item
  span {
  white-space: nowrap;
  padding-right: 0.3125rem;
}
.page-main-container
  .main-right-section
  .session-detail-section
  .session-detail-list
  .btn-section
  .join-btn {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: var(--white-color);
  background-color: var(--purple-color);
  width: 100%;
}

.custom-modal .close-icon {
  position: absolute;
  top: -0.9375rem;
  right: -0.9375rem;
  background: var(--primary-text-color);
  color: var(--white-color);
  border: 0.0625rem solid var(--white-color);
  display: flex;
  align-items: center;
  border-radius: 100%;
  padding: 0.3125rem;
}
.custom-modal .modal-container {
  max-height: 31.25rem;
}
.custom-modal .modal-container {
  padding: 4.375rem;
}
.custom-modal .modal-container .img-section {
  width: 100%;
  height: 270px;
}
.custom-modal .modal-container .img-section img {
  width: 100%;
  height: 100%;
}
.custom-modal .modal-container p {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .page-main-container .zoom-container {
    flex-wrap: wrap;
  }
  .page-main-container .zoom-container .main-left-section {
    width: 100%;
    margin-right: 0;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="date"] {
  cursor: pointer;
}

.container-xl {
  max-width: 90%;
  margin: 0 auto;
}

.container-xxl {
  max-width: 67.5rem !important;
}

.container {
  max-width: 102.5rem !important;
}

.banner-bg {
  /* background-image: url(../img/auth-bg.svg); */
  background-size: cover;
  background-repeat: no-repeat;
}

.img-width-height {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.courseTitle a {
  color: #2d2f31 !important;
}

/* font-size css starts */

.fs-12 {
  font-size: 0.75rem;
}

.fs-14 {
  font-size: 0.875rem;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-20 {
  font-size: 1.25rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-32 {
  font-size: 2rem !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.fs-36 {
  font-size: 2.25rem !important;
}

.fs-48 {
  font-size: 3rem !important;
}

/* font-size css ends */

/* font-weight css starts */

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

/* font-weight css ends */

/* text-color css starts */

.primary-text-color {
  color: var(--primary-text-color) !important;
}

.secondary-text-color {
  color: var(--secondary-text-color) !important;
}

.loginText a {
  color: #7950e2 !important;
}

.loginText {
  text-align: center !important;
  margin: 10px !important;
}

.purple-text-color {
  color: var(--purple-color) !important;
}

.purple-text-color {
  color: var(--purple-color) !important;
}

.white-text-color {
  color: var(--white-color) !important;
}

.red-text-color {
  color: var(--red-color);
}

.green-text-color {
  color: var(--green-color);
}

/* text-color css ends */

/* margin and padding css starts */

.mt-80 {
  margin-top: 5rem;
}

.mt-100 {
  margin-top: 6.25rem;
}

.mb-100 {
  margin-bottom: 6.25rem;
}

.mt-150 {
  margin-top: 9.375rem !important;
}

.mb-150 {
  margin-bottom: 9.375rem !important;
}

.pt-80 {
  padding-top: 5rem;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.pb-150 {
  padding-bottom: 9.375rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.mb-80 {
  margin-bottom: 5rem;
}

.mt-mb-150 {
  margin: 9.375rem 0;
}

.pt-62 {
  padding-top: 3.875rem;
}

.pb-62 {
  padding-bottom: 3.875rem;
}

/* margin and padding css ends */

/* button css starts */

.purple-bg-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: var(--purple-color) !important;
  border: none;
  white-space: nowrap;
  padding: 16px !important;
}

.white-bg-btn {
  color: var(--primary-text-color);
  background-color: var(--border-grey-color);
  border: none;
  padding: 0.9375rem;
}

button {
  border-radius: 0 !important;
  font-family: "Be Vietnam Pro", sans-serif !important;
}

/* button css ends */
.page {
  margin: 0 !important;
}

.sign-up-btn {
  line-height: 1.265rem;
  color: var(--purple-color) !important;
  text-decoration: none;
}

.close-btn {
  position: absolute;
  right: -0.9375rem;
  top: -0.9375rem;
  border: 0.0625rem solid var(--white-color);
  border-radius: 100% !important;
  background: var(--black-color) !important;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0 !important;
}

/* checkbox design css start */

.form-check-input {
  border: 0.0625rem solid var(--border-grey-color) !important;
  margin-right: 0.625rem;
  margin-top: 0;
  border-radius: 0;
  width: 1.25rem;
  height: 1.25rem;
}

.full-btn {
  padding: 1rem 1rem 0.9375rem 1rem !important;
  background: var(--purple-color) !important;
  box-shadow: 0rem 0.0625rem 0.125rem 0rem var(--black-color-0D);
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.063rem !important;
  color: var(--white-color) !important;
  cursor: pointer !important;
  outline: none;
  border-radius: 0 !important;
  position: relative;
}

.full-btn:hover {
  color: var(--white-color);
  background-color: var(--purple-color);
}

.full-btn.full-btn-border {
  background: transparent !important;
  color: var(--secondary-text-color) !important;
  font-weight: 400;
  box-shadow: 0rem 0.0625rem 0.125rem 0rem var(--black-color-0D);
  border: 0.0625rem solid var(--border-grey-color);
}

.full-btn.full-btn-border:hover {
  border-color: var(--border-grey-color);
}

.heart-btn {
  padding: 0.8125rem;
  border: 0.0625rem solid var(--primary-text-color);
}

.course-by a {
  color: #2d2f31;
}

.course-by a:hover {
  color: #7950e2;
}

.heart-btn.active {
  background-color: var(--purple-color);
  border: 0.0625rem solid var(--purple-color);
}

.heart-btn.active svg path {
  fill: var(--white-color);
}

.sort-btn {
  background-color: var(--white-color);
  padding: 0.9375rem;
  border: 0.0625rem solid var(--primary-text-color) !important;
}

.error {
  color: red;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin-top: 5px;
}

/* button css ends */

/* input design css start  */

.input-field {
  padding: 0.95rem !important;
  border: 0.0625rem solid var(--border-grey-color) !important;
  border-radius: 0 !important;
  color: var(--primary-text-color) !important;
  box-shadow: 0rem 0.0625rem 0.125rem 0rem var(--black-color-0D);
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.1069rem !important;
  width: 100% !important;
  outline: none;
  box-shadow: unset;
}

.input-field.is-invalid {
  border: 0.0625rem solid var(--red-color) !important;
  background-image: unset;
  color: var(--red-color) !important;
}

.input-field::placeholder {
  color: var(--secondary-text-color) !important;
  font-size: 0.875rem;
  font-weight: 400;
}

.form-group p {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.265rem;
  color: var(--primary-text-color);
}

textarea {
  border-radius: 0 !important;
}

/* input design css end */

.page {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
}

a:focus {
  outline: unset !important;
}

.border-bottom {
  border-bottom: 0.0625rem solid var(--border-grey-color) !important;
}

.checkbox-border {
  border: 0.0625rem solid var(--primary-text-color) !important;
}

.rdio {
  position: relative;
}

.rdio input[type="radio"] {
  opacity: 0;
}

label {
  padding-left: 0.9375rem;
  cursor: pointer;
}

.rdio label:before {
  width: 1.25rem;
  height: 1.25rem;
  position: absolute;
  top: 0.0625rem;
  left: 0;
  content: "";
  display: inline-block;
  -moz-border-radius: 3.125rem;
  -webkit-border-radius: 3.125rem;
  border-radius: 3.125rem;
  border: 0.0625rem solid var(--primary-text-color);
  background: var(--white-color);
}

.rdio input[type="radio"] {
  margin: 0rem;
}

.rdio input[type="radio"]:disabled + label {
  color: #999;
}

.rdio input[type="radio"]:disabled + label:before {
  background-color: var(--purple-color);
}

.rdio input[type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  top: 0.3125rem;
  left: 0.25rem;
  display: inline-block;
  font-size: 0.6875rem;
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--purple-color);
  -moz-border-radius: 3.125rem;
  -webkit-border-radius: 3.125rem;
  border-radius: 3.125rem;
}

.checkbox-border-radius {
  border-radius: 0 !important;
}

.accordion-item {
  border: unset !important;
}

.accordion-button {
  padding: 0.625rem 0 !important;
}

.accordion-body {
  padding: 0.625rem 0 !important;
}

.accordion-button:not(.collapsed) {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--primary-text-color) !important;
  background-color: unset !important;
  box-shadow: unset !important;
}

.accordion-button:focus {
  z-index: 0 !important;
  border-color: unset !important;
  box-shadow: unset !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}

.page-link {
  border: unset !important;
  color: var(--primary-text-color) !important;
  transition: unset !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  border-radius: 0 !important;
}

.page-numbers {
  border: unset !important;
  color: var(--primary-text-color) !important;
  transition: unset !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  border-radius: 0 !important;
  padding: 10px !important;
}

.active > .page-link {
  background-color: var(--purple-color-1A) !important;
  color: var(--primary-text-color) !important;
}

.page-link:focus {
  color: var(--primary-text-color) !important;
  background-color: var(--purple-color-1A) !important;
  box-shadow: unset !important;
}

/* checkbox design css start */

.form-check-input {
  margin-right: 0.625rem;
  margin-top: 0 !important;
  border-radius: 0;
  width: 1.25rem;
  height: 1.25rem;
}

.form-check-input:checked {
  background-color: var(--purple-color) !important;
  border-color: var(--purple-color) !important;
}

.form-check-input:focus {
  border-color: unset !important;
  outline: unset !important;
  box-shadow: unset !important;
}

/* checkbox design css end */

/* header design css starts */

.header-section {
  position: relative;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--grey-color);
  box-shadow: 0rem 0.0625rem 0.625rem 0rem var(--black-color-1A);
}

/* .header {
  position: relative;
  padding: 0.9375rem 0;
} */

.header-inner {
  padding: 0rem 5%;
}

.input .form-control {
  border-radius: 3.75rem;
  font-size: 0.875rem;
  padding: 0.875rem 0.875rem 0.875rem 3.75rem;
  border: 0.0625rem solid var(--border-grey-color);
}

.header .left-section .image-section {
  margin-right: 5.75rem;
}

.header-section .left-section p {
  cursor: pointer;
}

.middle-section {
  position: relative;
  flex: 1 !important;
}

.header .right-section img {
  cursor: pointer;
}

.heart {
  margin-right: 1.25rem;
}

.right-section .signup-btn,
.icons-section .signup-btn {
  white-space: nowrap;
  border: none;
  background-color: transparent;
  color: var(--primary-text-color);
  padding: 0.625rem 1.25rem 0.625rem 0.625rem;
}

.right-section .signup-btn.seller-btn {
  padding: 0.625rem !important;
  margin-left: 3.3125rem;
}

.right-section .login-btn,
.icons-section .login-btn {
  padding: 0.625rem 2.125rem !important;
}

.right-section .login-btn a {
  color: #ffffff;
}

.header .left-section .categories-section {
  width: 50%;
}

.header .left-section .categories-section p:hover {
  color: var(--purple-color);
}

.profileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.input .search-icon {
  position: absolute;
  top: 0.9375rem;
  left: 2.1875rem;
}

.input .signup-btn {
  white-space: nowrap;
  border: none;
  background-color: transparent;
  color: var(--primary-text-color);
  margin-right: 1.875rem;
  margin-left: 1.25rem;
}

.input .login-btn {
  margin-right: 1.875rem;
  padding: 0.625rem 2.125rem;
}

.hover-section {
  position: absolute;
  top: 3.375rem;
  left: 0;
  width: 100%;
  background-color: var(--white-color);
  box-shadow: 0rem 0.25rem 1.875rem 0rem var(--black-color-26);
  z-index: 9;
  padding: 2.5rem;
  /* background-image: url(../img/background-img.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  display: none;
}

.show-hover-section {
  display: block !important;
}

.categories-section:hover .hover-section {
  display: block;
}

.hover-section ul {
  padding: 0;
  /* border-bottom: 0.0625rem solid var(--grey-color); */
  flex-wrap: wrap;
  margin: auto;
}

.hover-section ul li {
  list-style: none;
  min-width: 18.4375rem;
  margin-right: 1rem;
  /* margin-bottom: 1.875rem; */
  margin-top: 0.9375rem;
}

.hover-section ul li h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary-text-color);
  margin-bottom: 1.5rem;
}

.hover-section ul li a {
  color: var(--secondary-text-color);
  text-decoration: none;
  padding-left: 1.875rem;
  /* margin-bottom: 0.875rem; */
  position: relative;
  cursor: pointer;
}

.catagoriesname {
  display: flex;
}

.hover-section ul li a:hover {
  color: var(--primary-text-color);
}

.hover-section ul li a:last-child {
  margin-bottom: 0;
}

.nav-item > a:first-child {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #2d2f31;
  padding: 0px;
}

.catagoriesname ul li a::before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border: 0.1875rem solid var(--purple-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  top: 0.3125rem;
  left: 0rem;
}

.submenus ul li a::before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border: 0.1875rem solid var(--purple-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  top: 0.375rem;
  left: 0rem;
}

.submenus li a {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
  color: var(--secondary-text-color) !important;
  padding-left: 1.5625rem !important;
}

.submenus li a:hover {
  color: var(--primary-text-color) !important;
}

.hover-section .list-two {
  border-bottom: none;
  margin-bottom: 0;
}

.list-two li {
  margin-bottom: 0 !important;
}

.shopping-cart-section {
  position: relative;
}

.shopping-cart-section .shopping-cart-btn {
  border: unset;
  border-color: unset;
  border-radius: unset;
  background: unset;
  color: unset;
  line-height: 1;
  padding: unset;
  cursor: pointer;
}

.cartRemoveBtn {
  position: absolute;
  top: 0px;
  right: -0.3125rem;
  border: unset;
  border-color: unset;
  border-radius: unset;
  background: unset;
  color: unset;
  line-height: 1;
  padding: unset;
  cursor: pointer;
}

.shopping-cart-hover-section {
  position: absolute;
  top: 100%;
  left: -0.625rem;
  transform: translateX(-80%);
  padding: 1.25rem;
  border: 0.0625rem solid var(--border-grey-color);
  box-shadow: 0rem 0.25rem 1.25rem 0rem var(--black-color-1A);
  background-color: var(--white-color);
  z-index: 3;
  min-width: 18.25rem;
  cursor: pointer;
  display: none;
  min-height: 15.3125rem;
  overflow-y: auto;
}

.cartItems {
  max-height: 31.25rem;
  overflow-y: auto;
}

.shopping-cart-section:hover .shopping-cart-hover-section {
  display: block;
}

.shopping-cart-hover-section.show {
  display: block;
}

.cart-subtotal {
  border-top: 0.0625rem solid var(--border-grey-color) !important;
  margin-top: 5px;
}

.cart-heading {
  padding-bottom: 0.9375rem;
  /* border-bottom: 0.0625rem solid var(--border-grey-color); */
  flex-direction: column;
  max-height: 6.4375rem;
  overflow-y: auto;
}

.purple-cross-icon {
  transform: translate(0%, 100%);
}

.cartDetails {
  position: relative;
  display: flex;
  margin: 0.625rem 0rem;
  flex-wrap: wrap;
  gap: 0.625rem;
}

.cart-image-section {
  width: 5rem;
  height: 3.75rem;
}

.cart-body-section {
  text-align: center;
  font-weight: 500;
}

.cart-body-section p {
  padding: 0.9375rem;
}

.cart-text {
  position: relative;
}

.purple-cross-icon {
  position: absolute;
  right: 0;
  top: -0.3125rem;
}

.shopping-cart-hover-section .cart-text {
  margin-left: 0.625rem;
}

.login-modal {
  position: fixed !important;
  top: 0 !important;
}

.menu-section {
  max-height: 3.0625rem;
  border-top: 0.0625rem solid var(--border-grey-color);
}

.menu-section .container-fluid {
  padding: 0;
}

.menu-section .nav-item .nav-link {
  color: var(--primary-text-color);
  padding: 0 !important;
}

.menu-section .nav-link:hover {
  border-bottom: 0.1875rem solid var(--purple-color);
  transition: none;
}

.menu-section .nav-item {
  margin-right: 2.1875rem;
  white-space: nowrap;
  padding: 0.25rem 0 0.5rem;
  position: relative;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-item a {
  color: #2d2f31;
}

.nav-items a {
  font-family: Be Vietnam Pro;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 20.24px;
  color: #2d2f31 !important;
}

.nav-item:hover .menu-hover-section {
  display: block;
}

#navbarSupportedContent {
  flex-direction: column;
  height: auto;
}

.menu-hover-section {
  position: absolute;
  top: 2.25rem;
  left: 0;
  transform: translateX(-50%) !important;
  z-index: 2;
  min-width: 12.5rem;
  width: auto;
  background-color: var(--white-color);
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-text-color);
  padding: 2.5rem 1.875rem;
  box-shadow: 0rem 0.25rem 1.875rem 0rem var(--black-color-26);
  white-space: nowrap;
  border-radius: 0;
  border-bottom: 0.1875rem solid var(--purple-color);
  display: none;
  list-style: none;
  padding-left: 3.75rem;
  overflow-y: auto;
  max-height: 267px;
}

.menu-hover-section a {
  color: #939393 !important;
}

.menu-hover-section .menu-item-type-taxonomy:hover a {
  color: #000000 !important;
}

.menu-hover-section::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: transparent transparent var(--white-color) transparent;
}

.menu-hover-section ul {
  margin: 0;
  padding: 0;
}

.menu-hover-section ul li {
  list-style: none;
  margin-bottom: 0.875rem;
  position: relative;
  display: flex;
  justify-content: start;
  position: relative;
  left: 2.1875rem;
}

.menu-hover-section li::before {
  content: "";
  width: 0.75rem;
  height: 0.75rem;
  border: 0.1875rem solid var(--purple-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  top: 0.6375rem;
  left: -1.875rem;
}

.menu-hover-section ul li:last-child {
  margin-bottom: 0;
}

.menu-hover-section ul li a {
  text-decoration: none;
  font-size: 1rem !important;
  font-weight: 400 !important;
  color: var(--secondary-text-color);
}

.navTrigger {
  display: none;
}

.profile-section {
  margin-left: 1.25rem;
}

.profile-section .dropdown .image-section {
  width: 2.75rem;
  height: 2.75rem;
}

.profile-section .dropdown .image-section img {
  border-radius: 100%;
  border: 0.1875rem solid var(--purple-color-1A);
}

.profile-section .profile-image {
  width: 2.75rem;
  height: 2.75rem;
}

.mobileMenu a {
  margin: 0px !important;
}

.mobileMenu li {
  margin: 10px 5px !important;
}

/* header design css ends */

.slider-section {
  max-width: 100%;
  margin: 0 auto;
  /* background-image: url(../img/slider-background-img.svg); */
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-section .left-section {
  margin: 50px 0;
}

.slider-section .left-section h2 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--primary-text-color);
  margin-bottom: 2.0625rem;
  width: 102%;
}

.slider-section .left-section span {
  font-size: 4rem;
  font-weight: 700;
  color: var(--purple-color);
}

.btn-section .join-now-btn {
  font-size: 1.125rem;
  font-weight: 700;
  color: var(--white-color);
  border: none;
  background-color: var(--purple-color);
  padding: 1.1875rem 2rem;
  margin-right: 0.9375rem;
}

.btn-section .free-courses-btn {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--primary-text-color);
  border: none;
  background-color: var(--white-color);
  padding: 1.1875rem 2rem;
  border: 0.0625rem solid var(--black-color);
}

.slider-section .carousel-control-prev {
  top: 41%;
  left: 3%;
  width: 2.75rem;
  height: 2.75rem;
  box-shadow: 0rem 0.0625rem 0.625rem 0rem var(--black-color-26);
  background-color: var(--white-color-F5);
  border-radius: 100% !important;
  opacity: 1;
}

.slider-section .carousel-control-next {
  top: 41%;
  right: 3%;
  width: 2.75rem;
  height: 2.75rem;
  box-shadow: 0rem 0.125rem 0.625rem 0rem var(--black-color-26);
  background-color: var(--primary-text-color);
  border-radius: 100% !important;
  opacity: 1;
  border: 0.0625rem solid var(--white-color);
}

.courses-section {
  margin-top: 150px !important;
}

.courses-section .heading h2 {
  text-align: center;
  font-size: 2.25rem;
  font-weight: 700;
  color: var(--primary-text-color);
  margin-bottom: 1.875rem;
}

.courses {
  margin-bottom: 6.875rem;
  gap: 20px;
}

.courses .course-name {
  width: 24%;
  height: 7.5rem;
  background: linear-gradient(
    180deg,
    rgba(250, 244, 254, 0.2) 0%,
    rgba(121, 80, 226, 0.2) 100%
  );
  border-radius: 0 3.75rem 0 3.75rem;
  margin-bottom: 2.5rem;
}

.course-name p {
  font-size: 1.75rem;
  font-weight: 600;
  color: var(--purple-color);
}

.course-name .top-arrow {
  position: absolute;
  bottom: 0.625rem;
  right: 0.625rem;
  background-color: var(--primary-text-color);
  border-radius: 100%;
  width: 2rem;
  height: 2rem;
  display: flex;
}

.courses-details .right-section {
  padding-top: 3.125rem;
}

.courses-details .right-section .name {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--purple-color);
  margin-bottom: 0.625rem;
}

.courses-details .right-section h1 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-text-color);
}

.text-section {
  position: relative;
}

.course-details-section ul {
  margin: 0;
}

.courses-details-section ul li {
  list-style: none;
}

.course-detail .image-section img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.course-details-section ul li p {
  font-size: 1.25rem;
  color: var(--secondary-text-color);
}

.top-courses-section {
  padding: 0 0 6.25rem;
}

.development-section-padding {
  padding: 0 3.75rem !important;
}

.development-section-padding .development-section-padding-bottom {
  padding-top: 40px !important;
}

.development-section-padding .development-section-padding-bottom:first-child {
  padding-top: 0px !important;
  border-top: none !important;
}

.development-section-padding .development-section-padding-bottom:nth-child(2) {
  padding-top: 0px !important;
  border-top: none !important;
}

.development-section-padding .development-section-padding-bottom:nth-child(3) {
  padding-top: 0px !important;
  border-top: none !important;
}

.development-section-margin {
  margin-bottom: 0 !important;
}

.development-section h2,
.personal-development h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-text-color);
  margin-bottom: 1.875rem;
}

.carousel-item .card,
.development-section .card {
  border: none;
  border-radius: 0;
}

.card-title {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--primary-text-color);
}

.card .card-body {
  padding: 1.25rem 0 0;
}

.card-details .card-text {
  font-size: 0.875rem;
  color: var(--secondary-text-color);
  margin-bottom: 0.5rem;
}

.card-details .rating {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--primary-text-color);
  margin-right: 0.3125rem;
  margin-bottom: 0.625rem;
}

.card-details .prize {
  font-size: 1rem;
  font-weight: 600;
  color: var(--primary-text-color);
}

.date {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: var(--secondary-text-color);
}

.date span {
  display: flex;
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  background-color: var(--black-color);
  margin: 0 0.625rem;
  border-radius: 100%;
}

.development-section {
  margin-bottom: 5rem;
  clip-path: inset(-8vw -1.3vw -5vw -1.3vw);
}

.our-blogs-section h2 {
  text-align: center;
  margin-bottom: 1.875rem;
}

.blogs-section .card-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-text-color);
}

.lets-read-btn {
  background-color: var(--white-color);
  border: none;
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-text-color) !important;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 5px;
}

.lets-read-btn a {
  color: #2d2f31;
}

.lets-read-btn::after {
  content: "";
  position: absolute;
  width: 3.5rem;
  height: 0.125rem;
  background-color: var(--purple-color);
  bottom: 0;
}

.ratings-section {
  margin-bottom: 150px;
}

.ratings-section h2 {
  font-weight: 700;
  margin-bottom: 1.875rem;
}

.ratings-section .card {
  border: 0.0625rem solid var(--border-grey-color);
}

.ratings-section .card p {
  font-size: 0.875rem;
  color: var(--primary-text-color);
  line-height: 1.4375rem;
  margin-bottom: 3.125rem;
  padding: 1.875rem 1.875rem 0;
}

.ratings-section .card-body {
  padding: 4.375rem 1.875rem 1.875rem;
}

.ratings {
  display: flex;
}

.username .left-section {
  padding: 0;
  width: 50px;
  height: 50px;
  max-width: none;
  border-radius: 50%;
  overflow: hidden;
}

.username .left-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.username .right-section {
  margin-left: 0.625rem;
  max-width: 10.625rem;
}

.username .right-section .name {
  font-weight: 600;
  color: var(--primary-text-color);
  margin-bottom: 0.3125rem;
}

.image-name {
  color: var(--white-color) !important;
}

.username .right-section .education {
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--secondary-text-color);
  margin-bottom: 0;
  padding: 0;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.footer-email {
  min-width: 19.5625rem;
}

.footer-section {
  background-color: var(--primary-text-color);
  padding: 6.25rem 0 3.75rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

.footer-section .footer-container {
  padding: 0 8%;
}

.details-section p {
  font-size: 1.125rem;
  color: var(--secondary-text-color);
  margin-top: 1.875rem;
  width: 85%;
  margin-bottom: 0;
}

.links-section ul {
  padding: 0;
  margin: 0;
}

.links-section ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.links-section ul li h3 {
  font-size: 1.25rem !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  margin-bottom: 0.875rem !important;
}

.links-section ul li a {
  text-decoration: none;
  font-size: 1.125rem;
  color: var(--white-color);
  margin-bottom: 0.9375rem;
}

.social-profiles-section ul {
  padding: 0;
  margin: 0;
}

.social-profiles-section ul li {
  list-style: none;
}

.social-profiles-section ul li h3 {
  font-size: 1.25rem !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  margin-bottom: 0.875rem !important;
}

.social-profiles-section ul li a {
  width: 3.25rem;
  height: 3.125rem;
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.875rem;
}

.social-links {
  display: flex;
  margin-bottom: 2.5rem;
}

.email-address .form-control {
  border-radius: 0;
  padding: 0.75rem 1rem;
}

.subscribe-btn {
  padding: 0.9375rem;
}

.copyright-section {
  padding: 0.875rem 0;
  background-color: var(--primary-text-color);
}

.copyright-section .copyright {
  padding: 0 8%;
}

.copyright-section p {
  color: var(--white-color);
  margin-bottom: 0;
}

.copyright-section ul {
  padding: 0 !important;
  margin: 0 !important;
}

.copyright-section ul li {
  list-style: none;
}

.copyright-section ul li a {
  font-size: 1rem;
  font-weight: 400;
  color: var(--white-color);
  text-decoration: none;
  position: relative;
  margin-right: 1rem;
}

.social-profiles-section ul li a:last-child {
  margin-right: 0;
}

.copyright-section ul li a::after {
  content: "";
  position: absolute;
  bottom: 0.125rem;
  width: 0.0625rem;
  height: 1rem;
  background-color: var(--white-color);
  margin-left: 0.5625rem;
}

.copyright-section ul li a:last-child::after {
  display: none;
}

/* footer design css ends */

.card {
  position: relative;
}

.card-inner {
  position: relative;
}

.video-modal-one {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: unset !important;
}

.card-inner-section {
  position: relative;
}

.card-inner-section .card-thumbnail .img-section {
  width: 100%;
  height: 11.875rem;
}
.card-inner-section .card-thumbnail .img-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-inner-section .development-card a {
  width: 100%;
  height: 11.875rem;
}
.card-inner-section .development-card a img {
  width: 100%;
  height: 100%;
  max-width: unset;
  object-fit: cover;
}
.card-inner-section .development-card .img-section {
  width: 100%;
  height: 11.875rem;
}
.card-inner-section .development-card .img-section img {
  width: 100%;
  height: 100%;
  max-width: unset;
  object-fit: cover;
}

.card-inner-section .slick-card .img-section {
  width: 100%;
  height: 11.875rem;
}
.card-inner-section .slick-card .img-section img {
  width: 100%;
  height: 100%;
  max-width: unset;
  object-fit: cover;
}

.card-hover-section {
  position: absolute;
  top: -31%;
  right: -16.4875rem;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--border-grey-color);
  box-shadow: 0rem 0.25rem 1.25rem 0rem var(--black-color-1F);
  border-radius: 0;
  width: 26rem;
  z-index: 9;
  display: none;
}

.hover-heart-btn {
  padding: 0.3125rem !important;
}

.card-hover-section .card-hover-inner {
  /* margin-bottom: 10px; */
  overflow-x: auto;
  max-height: 30rem;
}

.slick-active-last .card-hover-section {
  box-shadow: 0rem 0rem 1.25rem 0.25rem var(--black-color-1F);
  left: -16.4875rem;
  right: 0;
}

.webinar-card-hover-section {
  top: -140%;
  right: -1.25rem;
}

.card-hover-section::before {
  content: "";
  position: absolute;
  top: 60%;
  right: 100%;
  margin-top: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: transparent var(--white-color) transparent transparent;
}

.slick-active-last .card-hover-section::before {
  right: -1.25rem;
  border-style: solid;
  border-color: transparent transparent transparent var(--white-color);
}

.card-inner-section:hover .card-hover-section {
  display: block;
}

.card-hover-section .heading {
  padding: 1.25rem 1.25rem;
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

.heading h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--primary-text-color);
  margin-bottom: 1rem;
}

.heading h2 a {
  color: #2d2f31;
}

.course-detail {
  margin-bottom: 1.25rem;
}

.left-section .course-by h4 {
  font-size: 0.875rem;
  color: var(--secondary-text-color);
}

.left-section .course-by h4 {
  font-size: 0.875rem;
  color: var(--primary-text-color);
}

.course-detail .right-section p {
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-text-color);
}

.course-level-section {
  margin-bottom: 1.25rem;
}

.prize-section p {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-text-color);
}

.prize-section .heart-icon {
  padding: 0.75rem;
  border: 0.0625rem solid var(--primary-text-color);
}

.what-you-learn-list {
  padding: 1.25rem;
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

.what-you-learn-list li {
  list-style: none;
}

.what-you-learn-list li h3 {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  color: var(--primary-text-color);
}

.add-cart-btn-section {
  padding: 1.25rem;
}

.add-cart-btn {
  padding: 0.8125rem 7.75rem;
}

.add-cart-btn-section a {
  text-decoration: none;
}

.slider-btn .carousel-control-prev {
  top: 21%;
  left: -1%;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0rem 0.0625rem 0.625Prem 0rem var(--black-color-26);
  background-color: var(--secondary-text-color);
  border-radius: 100% !important;
  opacity: 1;
}

.slider-btn .carousel-control-next {
  top: 21%;
  right: 0.5%;
  width: 2.5rem;
  height: 2.5rem;
  box-shadow: 0rem 0.125rem 0.625rem 0rem var(--black-color-26);
  background-color: var(--primary-text-color);
  border-radius: 100% !important;
  opacity: 1;
  border: 0.0625rem solid var(--white-color);
}

.our-blogs-btn .carousel-control-prev {
  top: 25%;
}

.our-blogs-btn .carousel-control-next {
  top: 25%;
  right: -2%;
}

.ratings-btn .carousel-control-prev {
  top: 45% !important;
}

.ratings-btn .carousel-control-next {
  top: 45% !important;
}

.card-img {
  object-fit: cover;
}

.development-card {
  margin-right: 1.6875rem;
}

.blogs-card {
  margin-right: 3.125rem;
}
.blogs-card .blogs-img {
  height: 15rem;
  width: 100%;
}
.blogs-card .blogs-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.ratings-card {
  margin-right: 1.25rem;
  height: 100% !important;
  border: 0.0625rem solid var(--border-grey-color) !important;
  padding: 1.875rem;
}

.image-card {
  padding: 0 !important;
}

.card-img-overlay {
  bottom: 10% !important;
  padding: 0 1.875rem !important;
}

.modal-body-section {
  height: 15rem;
}

.embed-responsive-item {
  height: 100%;
}

.mobile-header {
  display: none !important;
}

.sidenav-open {
  overflow: hidden;
}

.modal-body {
  padding: 0 !important;
}

.modal-body .login-popup {
  padding: 2.5rem;
  overflow: auto;
}

.modal-body .login-popup .login-popup-inner {
  width: 100%;
  max-width: 35rem;
}

.remember-btn {
  line-height: 1.1069rem;
  color: var(--secondary-text-color);
}

.forget-password {
  text-decoration: none;
  line-height: 1.1069rem;
  color: var(--purple-color) !important;
}

.password-toggle {
  position: relative;
}

.password-toggle span {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: flex;
  cursor: pointer;
  width: 1.125rem;
  height: 1.0625rem;
}

.erroMsg {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  font-family: Be Vietnam Pro;
}

.backTologin {
  width: 100%;
  color: #939393;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.24px;
  gap: 5px;
}

.backTologin:hover {
  color: #939393;
}

.auth-page {
  height: 100vh;
  width: 100%;
}

.auth-page .left-section {
  /* background-image: url(../img/auth-bg.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 50%;
}

.auth-page .left-section .logo {
  position: absolute;
  top: 7%;
  left: 15%;
}

.auth-page .left-section .image-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-page .right-section {
  width: 50%;
  /* padding: 1.875rem;
    overflow: auto;
    max-height: calc(100vh - 0.1rem); */
}

.auth-page .right-section .right-section-inner {
  max-height: 100vh;
  overflow: auto;
  width: 100%;
}
.auth-page .right-section .right-section-content {
  overflow: auto;
  max-height: calc(100vh - 2rem);
  align-items: center;
}

.auth-page .right-section .right-section-content {
  justify-content: center;
}

.auth-page .right-section .right-section-inner form {
  max-width: 38rem;
  padding: 1.875rem;
  margin: 0 auto;
}

.remember-btn {
  line-height: 1.1069rem;
  color: var(--secondary-text-color);
}

.forget-password {
  line-height: 1.1069rem;
  color: var(--purple-color) !important;
}

.successMessage {
  display: flex;
  justify-content: center;
  color: green;
  padding: 24px 0px;
}

#coachingLanguage {
  visibility: hidden;
  width: 0px !important;
  height: 0px !important;
  padding: 0px !important;
}

#languageError {
  margin-bottom: 10px;
}

#otherLanguageInput {
  margin-bottom: 20px;
}

#multipleNote {
  display: none;
  margin-bottom: 20px;
}

/* auth page design css end */

/* select dropdown design css start  */
.selectBox {
  padding: 0.95rem !important;
  border: 0.0625rem solid var(--border-grey-color) !important;
  border-radius: 0 !important;
  color: var(--primary-text-color) !important;
  background-color: var(--white-color);
  box-shadow: 0rem 0.0625rem 0.125rem 0rem var(--black-color-0D);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1069rem;
  width: 100%;
  outline: none;
  cursor: pointer;
  position: relative;
}

.selectBox__value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.selectBox:after {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: all 0.2s ease-in-out;
  /* content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.001' height='8.165' viewBox='0 0 14.001 8.165'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23212121;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M13.861,60.224l-.7-.7a.441.441,0,0,0-.645,0L7,65.036,1.487,59.522a.441.441,0,0,0-.645,0l-.7.7a.441.441,0,0,0,0,.645l6.537,6.538a.441.441,0,0,0,.645,0l6.538-6.538a.442.442,0,0,0,0-.645Z' transform='translate(0 -59.382)'/%3E%3C/svg%3E"); */
}

.country_code::after {
  content: "" !important;
}

.selectBox .dropdown-menu {
  opacity: 0;
  display: block;
  top: 100%;
  width: 100%;
  max-height: 15.625rem;
  z-index: -1;
  overflow-y: auto;
  left: 0;
  visibility: hidden;
  border-radius: 0;
  min-width: 3.125rem;
}

.selectBox.show {
  background-color: #fff;
}

.selectBox.show:after {
  transform: translateY(-50%) rotate(180deg);
}

.selectBox.show .dropdown-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.selectBox .dropdown-menu .dropdown-item {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: left;
  padding: 0.625rem 0.875rem;
  color: var(--primary-text-color);
  display: flex;
  align-items: center;
}

.selectBox .dropdown-menu .dropdown-item p {
  margin: 0;
  padding-left: 0.5rem;
}

.selectBox .dropdown-menu .dropdown-item.active {
  background-color: var(--white-color-F5);
}

.selectBox .dropdown-menu .dropdown-item:active {
  background-color: var(--white-color-F5);
}

.country_code {
  min-width: rem;
  width: fit-content;
}

.selectBox.country_code {
  line-height: 1.313rem;
  cursor: unset;
}

.selectBox.country_code .selectBox__valu {
  text-align: center;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3.125rem white inset;
  -webkit-text-fill-color: var(--primary-text-color);
}

/* select dropdown design css end  */

/* breadcrumb design css start  */
.breadcrumb .breadcrumb-item a {
  font-weight: 400;
  color: var(--primary-text-color);
}

.breadcrumb .breadcrumb-item.active a {
  font-weight: 600;
  color: var(--purple-color);
}

.breadcrumb-nav ol {
  margin: 0;
}

.breadcrumb-item.active {
  color: #7950e2 !important;
}

/* breadcrumb design css end */

/* Contact us page css start  */
.contact_us_container {
  margin-top: 9.375rem;
  margin-bottom: 9.375rem;
}

.contact_us .contact_us_heading {
  margin-bottom: 1.875rem;
}

.contact_us .contact_us_heading h4 {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.7rem;
  color: var(--primary-text-color);
  margin-bottom: 0.625rem;
}

.contact_us .contact_us_heading p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 0;
  color: var(--secondary-text-color);
}

.contact-section .contact_icon {
  padding: 1rem;
  background-color: var(--purple-color-05);
  margin-right: 1.5rem;
}

.contact_us .contact-section {
  margin-bottom: 6.25rem;
}

.contact_us .social-media-section .social-media-icon {
  padding: 1.5625rem;
  border: 0.0625rem solid var(--border-grey-color);
  height: 5.375rem;
  width: 5.375rem;
}

.contact_us .social-media-section .social-media-icon:hover {
  box-shadow: 0rem 1.25rem 1.25rem 0rem var(--box-shadow-color);
}

.contact_us .social-media-section {
  margin-bottom: 3rem;
}

.send-message-box {
  padding: 2.5rem;
  background-color: var(--white-color-F5);
}

.map-section iframe {
  display: block;
  width: 100%;
  height: 30.625rem;
  margin: 0 auto;
}

/* Contact us page css end */

/* course-detail-page design css start */

.course-detail-page .banner-section {
  padding: 2.5rem 0;
  width: calc(100% - 28.25rem);
  max-width: 100%;
  margin-right: 5rem;
}

.course-detail-page .banner-title {
  margin: 2.5rem 0 1.25rem;
  position: relative;
}

.course-detail-page .banner-title::after {
  content: "";
  position: absolute;
  top: 0;
  left: -1rem;
  width: 0.1875rem;
  height: 100%;
  background-color: var(--purple-color);
}

.course-detail-page .banner-details {
  margin-bottom: 0.875rem;
}

.course-detail-page .language-icon {
  margin-right: 0.625rem;
}

.course-detail-page .ratings-section {
  margin-bottom: 1.25rem;
}

/* tab design css start  */

.tab-section.course-detail-tab {
  margin-top: 5rem;
  width: calc(100% - 28.25rem);
  max-width: 100%;
  margin-right: 5rem;
}

.tab-section .nav-tabs {
  margin: 0;
}

.tab-section .nav-tabs .nav-link {
  color: var(--primary-text-color);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5813rem;
  border: none;
  white-space: nowrap;
}

.tab-section .nav-tabs .nav-link.active {
  font-weight: 600;
  color: var(--purple-color) !important;
  border-color: unset;
  background-color: transparent;
  position: relative;
}

.tab-section .nav-tabs .nav-link.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background-color: var(--purple-color);
}

.tab-content-section {
  margin-top: 2.5rem;
}

.tab-content-section .tab-content-list .tab-content-item {
  margin-bottom: 2.5rem;
}

.tab-content-section .tab-content-list .tab-content-item:last-child {
  margin-bottom: 0;
}

.tab-content-section .tab-content-list .tab-content-item ul {
  margin-left: 0;
  padding-left: 1.7rem;
  margin-bottom: 0;
  padding-bottom: 0;
}

.tab-content-section .tab-content-list .tab-content-item ul li {
  list-style: disc;
  line-height: 1.875rem;
}

.tab-content-section .tab-content-list .tab-content-item .requirements-ul {
  padding-left: 0;
}

/* tab design css end */

.course-content-section {
  position: relative;
}

.course-card-section {
  padding: 1rem;
  position: sticky;
  top: 2.5rem;
  right: 8%;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--border-grey-color);
  box-shadow: 0rem 0.0625rem 0.9375rem 0rem var(--black-color-1A);
  height: 100%;
  margin-top: -20.625rem;
}

.course-card-section .media-preview {
  position: relative;
}

.course-card-section .media-preview {
  position: relative;
}

.course-card-section .media-preview .play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.course-card-section .media-preview .preview-text {
  position: relative;
}

.course-card-section .media-preview .preview-text span {
  position: absolute;
  bottom: 0.875rem;
  left: 50%;
  transform: translateX(-50%);
  color: var(--white-color);
  white-space: nowrap;
}

.course-card-section .live-tag {
  padding: 0.575rem 0.9375rem;
  background-color: var(--red-color);
  position: absolute;
  top: 0.6875rem;
  left: 0.9375rem;
  width: fit-content;
}

.course-card-section .live-tag span {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: var(--white-color);
}

.course-card-section .tab-section .price-section {
  margin: 1.25rem 0;
}

.course-card-section .tab-section .price-section .discount {
  color: var(--orange-color);
}

.course-card-section .tab-section .price-section .mrp-price {
  text-decoration: line-through;
}

.course-card-section .tab-section .coupon-section .apply-button {
  position: relative;
}

.course-card-section .tab-section .coupon-section .apply-button .apply-btn {
  position: absolute;
  top: 0.4375rem;
  right: 0.4375rem;
  padding: 0.5rem 0.875rem;
}

.course-card-section .tab-section .social-media .social-media-btn {
  padding: 0.875rem;
  border: 0.0625rem solid var(--border-grey-color);
  cursor: pointer;
}

.course-card-section .tab-section .social-media .copy-btn {
  padding: 0.875rem 0.65rem;
}

.page-item {
  padding: 10px;
}

/* course-detail-page design css end */

/* accordion design css start */

.custom-accordion {
  padding-top: 1.25rem;
  margin-bottom: 5rem;
}

.custom-accordion .accordion-collapse {
  padding: 0;
}

.load-more-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 13.125rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -24.08%,
    #ffffff 94.79%
  );
  display: flex;
  align-items: end;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.265rem;
  color: var(--purple-color) !important;
  padding-bottom: 0.9375rem;
  padding: 0;
}

.custom-accordion .accordion-item {
  border: 0.0625rem solid var(--grey-color) !important;
  border-radius: 0;
  margin: 15px 0px;
}

.custom-accordion .accordion-item:last-child {
  border-bottom: 0.0625rem solid var(--grey-color) !important;
}

.custom-accordion
  .accordion-item
  .accordion-header
  .accordion-button.collapsed
  span {
  color: var(--primary-text-color);
}

.custom-accordion .accordion-item .accordion-header .accordion-button span {
  font-size: 1rem;
  font-weight: 600;
  color: var(--purple-color);
}

.custom-accordion .accordion-item .accordion-header .accordion-button {
  padding: 1.125rem 1.25rem 1.125rem 4.8rem !important;
}

.custom-accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .header-icon {
  padding-right: 1rem;
}

.custom-accordion
  .accordion-item
  .accordion-header
  .accordion-button
  .header-icon
  .completed-message {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--green-color);
}

.custom-accordion
  .accordion-item
  .accordion-header
  .accordion-button.collapsed::after {
  /* background-image: url(../img/right-black-arrow.svg) !important; */
}

.custom-accordion .accordion-item .accordion-header .accordion-button::after {
  position: absolute;
  left: 2.5rem;
  /* background-image: url(../img/grey-dropdown-icon.svg) !important; */
}

.custom-accordion .accordion-item .accordion-header .accordion-button:focus {
  background-color: unset !important;
  border: none !important;
  outline: none !important;
  box-shadow: unset !important;
}

.custom-accordion .accordion-collapse .accordion-body {
  padding: 1.25rem 2.5rem !important;
}

.custom-accordion .accordion-collapse .accordion-body ul {
  padding: 0;
  margin: 0;
}

.custom-accordion .accordion-collapse .accordion-body ul li {
  padding-bottom: 1.25rem;
}

.custom-accordion .accordion-collapse .accordion-body ul li.course-list p {
  padding-bottom: 0.9375rem;
}

.custom-accordion .accordion-collapse .accordion-body ul li.course-list .icon {
  display: flex;
}

.custom-accordion .accordion-collapse .accordion-body ul li:last-child {
  padding-bottom: 0rem;
}

.custom-accordion .accordion-collapse .accordion-body ul li p {
  margin-bottom: 0rem;
}

.accordion-button:not(.collapsed) {
  background-color: unset;
}

.course-item-accordion .accordion-item:nth-child(n + 6) {
  display: none;
}

.course-item-accordion .accordion-item.showList:nth-child(n + 6) {
  display: block;
}

.error-message {
  background: var(--red-color-05);
  padding: 1.25rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: var(--red-color);
  line-height: 1.25rem;
}

.text-error-message {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: var(--red-color);
}

/* accordion design css end */

.instructor-section .instructor-profile-section {
  margin: 1.25rem 0 1.875rem;
}

.instructor-section .instructor-profile-section .instructor-logo {
  width: 7.5rem;
  height: 7.5rem;
  margin-right: 1.25rem;
  border-radius: 50%;
}

.instructor-section .instructor-profile-section .instructor-logo img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-heading {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5813rem;
  color: var(--purple-color);
  text-decoration: underline;
  margin-bottom: 0.875rem;
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-heading
  a {
  color: #7950e2;
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-list {
  padding-bottom: 0.625rem;
}

.instructor-section
  .instructor-profile-section
  .instructor-details
  .instructor-list:last-child {
  padding-bottom: 0rem;
}

.instructor-section .instructor-profile-section .instructor-details .icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}

.instructor-section .instructor-description {
  margin-bottom: 5rem;
}

.user-rating-section .user-profile-img {
  width: 4rem;
  height: 4rem;
}

.user-rating-section {
  margin: 3.4375rem 0 2.5rem;
}

.user-rating-section .user-profile-img img {
  width: 100%;
  height: 100%;
}

.user-rating-section .user-name {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5813rem;
  color: var(--primary-text-color);
  margin-bottom: 0.625rem;
}

.user-rating-section .user-message {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4375rem;
  color: var(--primary-text-color);
  margin: 0;
}

.user-rating-section .left-section .total-star {
  font-size: 3.75rem;
  font-weight: 700;
  line-height: 4.7438rem;
  color: var(--primary-text-color);
}

.user-rating-section .left-section .total-ratings {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.265rem;
  color: var(--black-color);
}

.user-rating-section .left-section {
  padding-right: 1.875rem;
  text-align: center;
}

.user-rating-section .right-section {
  flex: 1;
  border-left: 0.0625rem solid var(--border-grey-color);
  padding-left: 1.875rem;
}

.user-rating-section .right-section ul {
  margin: 0;
  padding: 0;
}

.user-rating-section .right-section .ratings-list {
  margin-bottom: 1.4375rem;
}

.user-rating-section .right-section .ratings-list .border {
  width: 100%;
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: var(--white-color-F5);
}

.user-rating-section .right-section .ratings-list .border .ratings-border {
  background-color: var(--purple-color);
  height: 0.5rem;
  border-radius: 0.25rem;
}

.user-rating-section .right-section .ratings-list .ratings-border {
  background-color: #7950e2;
  height: 8px;
  border-radius: 4px;
}

.user-rating-section .right-section .ratings-list .ratings-grey-border {
  width: 100%;
  height: 8px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.lp-review-svg-star.fas svg {
  fill: #f9931d !important;
}

.lp-review-svg-star.far svg {
  stroke: #f9931d !important;
}

/* course listing design starts */

.filter-heading .filter {
  padding: 0.7125rem 1rem;
  border: 0.0625rem solid var(--primary-text-color);
  white-space: nowrap;
}

.bestseller-img {
  position: absolute;
  top: 1.25rem;
  left: 0;
}

.button-dropdown::after {
  display: none !important;
}

.dropdown-position {
  transform: translate(0rem, 2.6875rem) !important;
}

.dropdown-menu {
  border: 0.0625rem solid var(--border-grey-color) !important;
  border-radius: 0 !important;
  box-shadow: 0rem 0rem 0.375rem -0.125rem #00000008,
    0rem 0.3125rem 0.625rem 0rem #00000014 !important;
  padding: 0.25rem 0 !important;
}

.dropdown-item {
  padding: 0.3125rem 0.875rem !important;
}

.dropdown-item:active {
  color: var(--primary-text-color) !important;
  background-color: unset !important;
}

.dropdown-item:hover {
  background-color: var(--white-color-F5) !important;
}

.header-dropdown {
  min-width: 15rem !important;
  padding: 0 !important;
}

.select-border {
  border: 0.0625rem solid var(--primary-text-color) !important;
}

.course-listing .col-lg-4:nth-child(n + 4) {
  margin-top: 2.5rem;
}

/* course listing design ends */

/* webinar listing design starts */

.horizontal-card {
  margin-left: 20px;
  padding-top: 0 !important;
}

.webinar-card {
  flex-direction: row !important;
  border: none !important;
}

.web_thumb_img {
  width: 280px;
  height: 141px;
}

.web_thumb_img img {
  width: 100%;
  height: 100%;
}

.card-title a {
  color: #2d2f31;
}

#product-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selected-filter {
  background-color: #f0f0f0;
  /* Customize the background color */
}

.webinarThubnail {
  width: 285px;
  height: 140px;
}

.webinarThubnail img {
  width: 285px;
  height: 140px !important;
  object-fit: cover;
}

/* webinar listing design ends */

/* webinar detail design starts */

.webinar-details-section .row {
  justify-content: center;
}

.webinar-details-section .right-section {
  margin: 0 0 0 8.4375rem;
  padding: 1.875rem 1.25rem 1.25rem;
  box-shadow: 0rem 0.0625rem 0.9375rem 0rem var(--black-color-1A);
  background-color: var(--white-color);
}

.webinar-card-heading {
  border-bottom: 0.0625rem solid var(--border-grey-color);
  margin-bottom: 1.25rem;
}

.webinar-card-body span {
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 100%;
  background-color: var(--primary-text-color);
  display: flex;
}

.webinar-card-footer .icon-section .icon {
  padding: 0.875rem;
  border: 0.0625rem solid var(--white-color-F5);
  margin-right: 0.375rem;
}

.webinar-card-footer .icon-section .icon:last-child {
  margin-right: 0;
}

.speaker-details-section {
  border: 0.0625rem solid var(--border-grey-color);
  padding: 2rem;
}

.speaker-details-section .image-section {
  width: 8.5rem;
  height: 8.5rem;
}

.speaker-details-section .image-section img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.speaker-info-section {
  margin-left: 1.5rem;
}

.course-rating-section {
  margin-right: 1.5rem;
}

.detailImg {
  width: 1360px;
  height: 600px;
}

.detailImg img {
  width: 100%;
  object-fit: cover;
}

/* webinar detail design ends */

/* cart listing design starts */

.cart-section,
.wishlist-section {
  margin: 10.625rem 0.3125rem 9.375rem 0.3125rem;
}

.shop-now-btn {
  padding: 1rem 1.75rem;
}

.cart-listing-section .cart-listing-table,
.order-history-section .order-history-table {
  border: 0.0625rem solid var(--border-grey-color);
}

.cart-listing-section .cart-listing-table thead,
.order-history-section .order-history-table thead {
  background-color: var(--white-color-F5);
}

.cart-listing-section .cart-listing-table th:first-child {
  padding: 1.25rem 0 1.25rem 3.125rem;
}

.cart-listing-section .cart-listing-table th:last-child {
  padding: 1.25rem 3.125rem 1.25rem 0;
}

.cart-listing-section .cart-listing-table td:first-child {
  padding: 1.25rem 0 1.25rem 3.125rem;
}

.cart-listing-section .cart-listing-table td {
  padding: 1.25rem 1.25rem 1.25rem 0;
}

.cart-listing-section .cart-listing-table td:last-child {
  padding: 1.25rem 3.125rem 1.25rem 0;
}

.cart-listing-table tr {
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

tbody h5 {
  width: 63%;
}

.small-cross-icon {
  position: absolute;
  padding: 0.25rem;
  background-color: var(--white-color);
  border: 0.0625rem solid var(--border-grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  top: -0.625rem;
  right: -0.625rem;
  cursor: pointer;
}

#totalCourses {
  margin-right: 5px;
}

.move-to-wishlist-btn {
  padding: 0.625rem 1rem !important;
  line-height: 1.25rem;
}

td .dot {
  width: 0.25rem;
  height: 0.25rem;
  display: flex;
  background-color: var(--secondary-text-color);
  border-radius: 100%;
}

.input-group > .form-control:focus {
  z-index: 0 !important;
  border-color: unset !important;
  box-shadow: unset !important;
  border: 0.0625rem solid var(--border-grey-color) !important;
}

.coupen-input {
  border-radius: 0 !important;
  padding: 0.625rem !important;
  border: 0.0625rem solid var(--border-grey-color) !important;
}

.input-group-text {
  position: absolute;
  top: 0.25rem;
  right: 0.2125rem;
  background-color: var(--purple-color) !important;
  border-radius: 0 !important;
}

.free-coupon {
  width: fit-content;
  border: 0.0625rem dashed var(--purple-color);
  background-color: var(--purple-color-0D);
  padding: 0.5625rem 1.125rem;
}

.view-all {
  border-bottom: 0.0625rem solid var(--purple-color);
}

.view-all:hover {
  color: var(--purple-color);
}

.coupon-modal {
  padding: 0 2.1875rem 2.1875rem !important;
  overflow-x: auto;
  max-height: 45rem;
  max-width: 40.625rem;
}

.coupon-modal .modal-header {
  padding: 0;
}

.coupon-header {
  padding: 2.1875rem 2.1875rem 1.875rem !important;
}

.apply-btn {
  background-color: var(--purple-color);
  padding: 0.5rem 0.875rem;
  height: 2.55rem;
  cursor: pointer;
}

.payment-failed-details-section {
  background-color: var(--red-color-0D);
  padding: 1.4375rem;
}

td .image-section {
  min-width: 10rem;
  height: 7.5rem;
}

.order-history-section .search-section .col-lg-5 {
  justify-content: end;
}

.order-history-section .order-history-table th {
  padding: 1.25rem 2.1875rem;
}

.order-history-section .order-history-table th {
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

.order-history-section .order-history-table td {
  padding: 1.75rem 2.1875rem;
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

.course-search {
  border: 0.0625rem solid var(--border-grey-color) !important;
  background-color: var(--white-color) !important;
  box-shadow: 0rem 0.0625rem 0.125rem 0rem #0000000d !important;
  padding: 0.75rem 3.0625rem !important;
}

.search-section .search-icon {
  position: absolute;
  top: 0.9375rem;
  left: 1.0625rem;
}

.filter-border {
  border: 0.0625rem solid var(--border-grey-color) !important;
  padding: 0.8125rem 0.9375rem;
}

.order-placed-section {
  background-color: var(--green-color-0D);
  padding: 1.4375rem;
}

.order-date-section {
  justify-content: space-between;
}

.order-date-section .date-section {
  border-right: 0.0625rem solid var(--secondary-text-color);
}

.viewDetailBtn {
  padding: 0.625rem 1rem;
}

.info-section .image-section {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--purple-color-0D);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

table ul {
  margin: 0;
  padding: 0;
}

table ul li {
  list-style: none;
}

.payment-info-section {
  border: 0.0625rem solid var(--border-grey-color);
  padding: 1.25rem;
  margin-left: 1.875rem;
}

.row-padding {
  padding: 0 6.25rem;
}

.line-through {
  text-decoration: line-through;
}

.order {
  margin-right: 1.875rem;
}

.form-control:focus {
  box-shadow: unset !important;
  border: 0.0625rem solid var(--border-grey-color) !important;
}

.offers {
  padding-bottom: 1.25rem;
}

/* cart listing design ends */

/* blog and blog listing page css start  */

.blog_page {
  margin: 0;
}

.blog_listing_page {
  margin: 6.25rem 0 9.375rem;
}

.blog_listing_page .left-section {
  padding-right: 1.875rem;
}

.blog_listing_page .left-section .recent-posts-section .image-section {
  min-width: 5.625rem;
  max-width: 5.625rem;
  width: 100%;
  height: 5.625rem;
}

.blog_listing_page
  .left-section
  .recent-posts-section
  .recent-posts-item
  .image-section
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_listing_page .left-section .recent-posts-section .recent-posts-item {
  margin-bottom: 1rem;
}

.blog_listing_page
  .left-section
  .recent-posts-section
  .recent-posts-item:last-child {
  margin-bottom: 0;
}

.blog_listing_page .left-section .recent-posts-section .recent-posts-item span {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--primary-text-color);
}

.blog_listing_page
  .left-section
  .recent-posts-section
  .recent-posts-item:hover
  span {
  color: var(--purple-color);
}

.blog_listing_page .tags-section {
  gap: 0.875rem;
  display: flex;
  flex-wrap: wrap;
}

.blog_listing_page .tags-section .tags-btn {
  border: 0.0625rem solid var(--border-grey-color);
  font-size: 1rem !important;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--primary-text-color);
  padding: 0.8125rem 1.25rem;
}

.blog_listing_page .tags-section .tags-btn:hover {
  background-color: var(--white-color-F5);
}

.blog_listing_page .tags-section .tags-btn.active {
  background-color: var(--purple-color);
  color: var(--white-color);
}

.blog_listing_page .right-section .blog-card-section .blog-card {
  border: 0.0625rem solid var(--border-grey-color);
  margin: 0rem 0.5rem 2.5rem;
}

.blog_listing_page .right-section .blog-card-section .blog-card .card-img {
  width: 100%;
  height: 18.25rem;
}

.blog_listing_page .right-section .blog-card-section .blog-card .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_listing_page .right-section .blog-card-section .blog-card .card-text {
  padding: 1.25rem;
}

.blog_listing_detail .blog_listing_title {
  margin-bottom: 2.5rem;
}

.blog_listing_detail .blog_listing_title h1 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.75rem;
  color: var(--primary-text-color);
  text-align: center;
  margin-bottom: 1.875rem;
}

.blog_listing_detail .blog_listing_title .border {
  border: 0.0625rem solid var(--border-grey-color) !important;
  min-width: 28.125rem;
  margin: 1rem 0;
}

.blog_listing_detail .blog_listing_title p {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: var(--purple-color);
  margin-bottom: 0;
}

.blog_listing_detail .blog_listing_title_img {
  width: 100%;
  height: 46.6875rem;
}

.blog_listing_detail .blog_listing_title_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog_listing_detail .left-section {
  padding-right: 6.25rem;
}

.blog_listing_detail .left-section .blog-profile-section .blog-logo {
  min-width: 5rem;
  min-height: 5rem;
  border-radius: 100%;
  margin-right: 1.875rem;
}

.blog-profile-section .blog-logo img {
  border-radius: 50%;
}

#blogDetails ul {
  list-style-type: none !important;
}

#blogDetails h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
  line-height: 30.36px !important;
}

#blogDetails .tags-section .tags-btn a {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #2d2f31;
}

.blogCatagoryBox {
  margin-top: 20px !important;
}

.blog_listing_detail .left-section .social-media-icon-section {
  border-bottom: 0.0625rem solid var(--border-grey-color);
  border-top: 0.0625rem solid var(--border-grey-color);
  padding: 1.875rem;
  margin: 1.875rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.9375rem;
}

.blog_listing_detail
  .left-section
  .social-media-icon-section
  .soial-media-icon {
  border: 0.0625rem solid var(--border-grey-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
}

.blog_listing_detail
  .left-section
  .social-media-icon-section
  .soial-media-icon:hover {
  background-color: var(--purple-color);
}

.blog_listing_detail
  .left-section
  .social-media-icon-section
  .soial-media-icon:hover
  svg
  path {
  fill: var(--white-color);
}

.blog_listing_detail .right-section .blog-card-section .view-posts-section {
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

/* blog and blog listing page css end */

/* free resources page css start  */
.free-resources-page {
  margin: 8.125rem 0 9.375rem;
}

.free-resources-page .free-resources-card {
  position: relative;
}

.free-resources-page .free-resources-card .youtube-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.free-resources-page .practice-resources-card-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5.75rem;
}

.free-resources-page .practice-resources-card {
  padding: 1.875rem 3.75rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.05) 0%,
    rgba(121, 80, 226, 0.05) 100%
  );
}

.free-resources-page .practice-resources-card .test-icon {
  margin-bottom: 1.125rem;
  display: flex;
  justify-content: center;
}

.free-resources-page .practice-resources-card .view-details-btn {
  background: var(--purple-color);
  border: 0.0625rem solid var(--purple-color);
  padding: 0.625rem 0.75rem;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  cursor: pointer;
}

.free-resources-page .practice-resources-card .view-details-btn span {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 0.875rem;
  color: var(--white-color);
  gap: 0.3125rem;
}

.free-resources-page .practice-resources-card .view-icon {
  margin-right: 0.3125rem;
  display: flex;
  align-items: center;
  width: 1.125rem;
  height: 1.125rem;
}

.free-resources-page .sort {
  justify-content: end;
}

/* free resources page css end */

/* course-preview-dialog css start  */

.course-preview-dialog {
  max-width: 80% !important;
  width: 100% !important;
  margin: 0 auto !important;
}

.course-preview-dialog .course-content {
  max-height: 90% !important;
  height: 100% !important;
  overflow: unset !important;
}

.course-preview-dialog .course-content {
  position: relative;
}

.close-button {
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
  background-color: var(--primary-text-color);
  color: var(--white-color);
  border: 0.0625rem solid var(--white-color);
  border-radius: 100% !important;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.3125rem;
  z-index: 1;
}

.course-preview-dialog .course-content .course-modal-body .modal-left-section {
  width: 70%;
  padding: 3.125rem 1.875rem 3.125rem 2.5rem;
  border-right: 0.0625rem solid var(--border-grey-color);
}

.course-preview-dialog
  .course-content
  .course-modal-body
  .modal-left-section
  .video-section {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 34.375rem;
  margin-top: 1.875rem;
}

.course-preview-dialog
  .course-content
  .course-modal-body
  .modal-left-section
  .video-section
  video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.course-preview-dialog .course-content .course-modal-body .modal-right-section {
  width: 30%;
  overflow: auto;
  max-height: calc(100vh - 15.375rem);
  margin: 3.75rem 0 1.875rem;
  padding: 0 1.875rem;
}

.course-preview-dialog
  .course-content
  .course-modal-body
  .modal-right-section
  .more-course-section
  .more-course-item {
  margin-bottom: 1.25rem;
}

.course-preview-dialog
  .course-content
  .course-modal-body
  .modal-right-section
  .more-course-section
  .more-course-item
  .image-section {
  min-width: 7.5rem;
  width: 7.5rem;
  height: 3.75rem;
  margin-right: 0.625rem;
}

.course-preview-dialog
  .course-content
  .course-modal-body
  .modal-right-section
  .more-course-section
  .more-course-item
  .image-section
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.course-preview-dialog
  .course-content
  .course-modal-body
  .modal-right-section
  .more-course-section
  .more-course-item
  span {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.225rem;
  color: var(--primary-text-color);
}

/* course-preview-dialog css end */

/* my profile design starts */

.my-profile-section {
  margin-bottom: 9.375rem;
}

.my-profile-section .left-section {
  padding: 2.5rem 0 1.5625rem 0;
  border-right: 0.0625rem solid var(--border-grey-color);
}

.my-profile-section .left-section p {
  padding: 0.9375rem 2.5rem;
  cursor: pointer;
}

.my-profile-section .edit-profile-section,
.my-profile-section .change-password-section {
  padding: 2.5rem 0 2.5rem 5rem;
}

.edit-profile-section .image-section {
  width: 6.25rem;
  height: 6.25rem;
}

.edit-profile-section .image-section img {
  border-radius: 100%;
  object-fit: contain;
}

.edit-profile-section .upload-image-section {
  margin-left: 1.875rem;
}

.change-password-btn {
  padding: 0.8125rem 1.25rem;
}

.change-password {
  margin-top: 1.875rem;
}

.password-main-section {
  display: none;
}

.section-active {
  background-color: var(--white-color-F5);
  color: var(--purple-color) !important;
  font-weight: 600 !important;
  /* color: red; */
}

.text-active {
  background-color: var(--white-color-F5);
  color: var(--purple-color) !important;
}

.date-of-birth {
  padding: 0.8rem !important;
}

#password_error {
  margin: 15px 0px;
}

/* 
#edit-profile-btn{
    color: #7950E2 !important;
} */
/* my profile design ends */

/* abourt page design starts */

.about-page-section .col-lg-7 .text-section {
  margin-left: 2.5rem;
}

.about {
  border: 0.0625rem solid var(--border-grey-color);
  height: 11.625rem;
  width: 23% !important;
}

.about:last-child {
  margin-right: 0;
}

.about:hover {
  background-color: var(--white-color-F5);
  box-shadow: 0.1875rem 0.1875rem 0.625rem 0rem #0000001a;
  border: none;
}

.work-section {
  background-color: var(--purple-color);
}

.browse-section:after {
  /* content: url(../img/down-arrow-img.svg); */
  position: absolute;
  right: -40%;
  top: -15%;
}

.purchase-section:after {
  /* content: url(../img/up-arrow-img.svg); */
  position: absolute;
  right: -50%;
  top: -15%;
}

.img-hover:hover {
  box-shadow: 0rem 0.0625rem 1.875rem 0rem #00000033;
}

/* about page design ends */

/* my webinar design starts */

.my-webinar-tab {
  width: 100% !important;
  margin-right: 0 !important;
}

/* my webinar design ends */

/* my course design starts */

.card-border {
  border: none !important;
}

.progress-bar-section .complete-border {
  width: 100%;
  height: 0.3125rem;
  border-radius: 0.25rem;
  background-color: var(--white-color-F5);
}

.progress-bar-section .complete-border .ratings-border {
  background-color: var(--purple-color);
  height: 0.3125rem;
  border-radius: 0.25rem;
}

.course-content-tab {
  border-right: 0.0625rem solid var(--border-grey-color);
  padding: 5rem 2.5rem 8.125rem 8.75rem;
}

.border-height {
  height: 0.5rem !important;
}

.border-bg {
  background-color: var(--border-grey-color) !important;
}

.controls-section {
  position: absolute;
  bottom: 2%;
  right: 0;
  width: 100%;
  padding: 0 1.25rem 0.625rem 1.25rem;
}

.course-content-heading {
  padding: 1.875rem;
}

.course-content-btn {
  width: fit-content !important;
}

.accordion-border {
  border-bottom: 0.0625rem solid var(--border-grey-color) !important;
}

.purple-checkbox-border {
  border: 0.0625rem solid var(--purple-color) !important;
}

.mobile-header-search {
  display: none;
}

.search-active {
  display: block;
}

.label {
  padding: 0.1875rem 0.3125rem 0.1875rem 0.625rem;
  background-color: var(--green-color);
  width: fit-content;
  position: absolute;
  top: 1.25rem;
  left: 0;
}

.card-label {
  position: relative;
  display: block;
}

.card-label::after {
  content: " ";
  position: absolute;
  top: -18%;
  left: 95%;
  margin-left: -0.3125rem;
  border-width: 0.8125rem;
  border-style: solid;
  border-color: var(--label-green-color) transparent transparent transparent;
}

.card-label::before {
  content: " ";
  position: absolute;
  bottom: -17%;
  left: 95%;
  margin-left: -0.3125rem;
  border-width: 0.8125rem;
  border-style: solid;
  border-color: transparent transparent var(--label-green-color) transparent;
}

.btn-close:focus {
  box-shadow: unset !important;
}

.accordion-header {
  cursor: pointer;
}

.bg-active {
  background-color: var(--purple-color-1A);
}

.bg-active .arrow-active {
  transform: rotate(180deg);
}

.bg-active .arrow-active path {
  stroke: var(--purple-color);
}

.bg-active .accordion-button {
  color: var(--purple-color);
  font-weight: 500 !important;
}

.recorded-session-section .accordion-button {
  padding: 0 !important;
}

.recorded-session-section .accordion-header {
  padding: 1.25rem;
}

.recorded-session-section .accordion-body {
  padding: 0 !important;
}

.topic-name {
  padding: 0.75rem 1.875rem;
  cursor: pointer;
}

.topic-name:hover {
  background-color: var(--white-color-F5);
}

.category-search-section {
  border-bottom: 0.0625rem solid var(--border-grey-color);
}

.category-search {
  padding: 0.75rem 0.875rem 0.75rem 2.125rem !important;
  border: none !important;
}

.category-search-icon {
  position: absolute;
  top: 0.8125rem;
  left: 0.625rem;
}

.category-item {
  padding: 0.375rem 1rem !important;
}

.mycorsePagination {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* my course design ends */

/* FAQ page css start  */
/* .faq-page{
    margin: 6.25rem 0 9.375rem;
}
.faq-accordion {
    display: flex;
    flex-direction: column;
}
.faq-accordion-item {
    padding: 2.5625rem 3.125rem;
    border: 0.0625rem solid var(--border-grey-color);
    margin-bottom: 1.875rem;
}
.faq-accordion-item .faq-accordion-item-title {
    position: relative;
    margin: 0;
    display: flex;
    width: 100%;
    font-size: 1.25rem;
    cursor: pointer;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0;
    box-sizing: border-box;
    align-items: center;
}
.faq-accordion-item .faq-accordion-item-desc {
    display: none;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    font-weight: 400;
    color: var(--secondary-text-color);
    margin: 3.125rem 0 1.25rem 0;
    box-sizing: border-box;
}
.faq-accordion-item input[type="checkbox"] {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
}
.faq-accordion-item input[type="checkbox"]:checked ~ .faq-accordion-item-title {
    border-bottom: 0.0625rem solid var(--purple-color);
    padding-bottom: 1.5rem;
}
.faq-accordion-item input[type="checkbox"]:checked ~ .faq-accordion-item-desc {
    display: block;
}
.faq-accordion-item
    input[type="checkbox"]:checked
    ~ .faq-accordion-item-title
    .plus-icon{
    transform: rotate(45deg);
}
.faq-accordion-item:first-child {
    margin-top: 0;
}
.faq-accordion-item .icon {
    width: 3.25rem;
    height: 3.25rem;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: var(--white-color-F5);
}

.free-resources-page .search-section .right-section{
    justify-content: end;
} */

.faq-page {
  margin: 6.25rem 0 9.375rem;
}

.faq-accordion {
  display: flex;
  flex-direction: column;
}

.faq-accordion-item {
  padding: 2.5625rem 3.125rem;
  border: 0.0625rem solid var(--border-grey-color);
  margin-bottom: 1.875rem;
  position: relative;
}

.faq-accordion-item .faq-accordion-item-title {
  position: relative;
  margin: 0;
  display: flex;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 0;
  box-sizing: border-box;
  align-items: center;
}

.faq-accordion-item .faq-accordion-item-desc {
  display: none;
  font-size: 1.125rem;
  line-height: 1.6875rem;
  font-weight: 400;
  color: var(--secondary-text-color);
  margin: 3.125rem 0 1.25rem 0;
  box-sizing: border-box;
}

.faq-accordion-item .faq-checkbox {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.faq-accordion-item .faq-checkbox:checked ~ .faq-accordion-item-title {
  border-bottom: 0.0625rem solid var(--purple-color);
  padding-bottom: 1.5rem;
}

.faq-accordion-item .faq-checkbox:checked ~ .faq-accordion-item-desc {
  display: block;
}

.faq-accordion-item
  .faq-checkbox:checked
  ~ .faq-accordion-item-title
  .plus-icon {
  transform: rotate(45deg);
}

.faq-accordion-item:first-child {
  margin-top: 0;
}

.faq-accordion-item .icon {
  min-width: 3.25rem;
  min-height: 3.25rem;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: var(--white-color-F5);
}

.free-resources-page .search-section .right-section {
  justify-content: end;
}

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error-page .image-section {
  text-align: center;
  width: 38rem;
  max-width: 80%;
  height: 38rem;
  max-height: 80%;
}

.error-page .image-section img {
  width: 100%;
  height: 100%;
}

.error-page .error-heading {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.8462rem;
  color: var(--primary-text-color);
  margin: 1.875rem 0 0.9375rem 0;
  text-align: center;
}

.error-page .description {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.4375rem;
  color: var(--secondary-text-color);
  text-align: center;
}

/* FAQ page css end */
/* slick slider css starts */

.slick-track {
  display: flex !important;
}

.slick-slide {
  margin: 0 0.8125rem !important;
}

.slick-list {
  margin: 0 -0.8125rem !important;
  overflow: unset !important;
}

.slick-prev {
  top: 29% !important;
  left: -15px !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  box-shadow: 0rem 0.0625rem 0.625rem 0rem var(--black-color-26) !important;
  background-color: var(--primary-text-color) !important;
  border-radius: 100% !important;
  opacity: 1;
  z-index: 1;
}

.slick-prev::before {
  /* content: url(../img/white-left-arrow.svg) !important; */
}

.slick-next {
  top: 29% !important;
  right: -0.9375rem !important;
  width: 2.5rem !important;
  height: 2.5rem !important;
  box-shadow: 0rem 0.125rem 0.625rem 0rem var(--black-color-26) !important;
  background-color: var(--primary-text-color) !important;
  border-radius: 100% !important;
  opacity: 1;
  border: 0.0625rem solid var(--white-color) !important;
}

.slick-next::before {
  /* content: url(../img/right-arrow.svg) !important; */
}

/* slick slider css ends */

/* responsive design css start */

@media screen and (max-width: 1700px) {
  .hover-section-img {
    max-width: 9.375rem;
    max-height: 6.25rem;
  }

  .hover-section-img img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 1280px) {
  .header-inner {
    padding: 0 2% 0 8%;
  }

  .tab-section .nav-tabs .nav-link {
    width: 25%;
  }

  .tab-section .course-nav .nav-link {
    width: 50% !important;
  }
}

@media only screen and (min-width: 1400px) {
  .my-webinar-tab .nav-tabs .nav-link {
    width: 20%;
  }
}

@media screen and (max-width: 1279px) {
  .menu-section {
    display: none;
  }

  .navbar-nav .nav-item:first-child .menu-hover-section {
    left: 0;
    transform: unset !important;
  }

  .mobile-header {
    display: block !important;
    padding: 1.25rem 0.75rem;
    background-color: var(--white-color);
    border: 0.0625rem solid var(--grey-color);
    box-shadow: 0rem 0.0625rem 0.625rem 0rem var(--black-color-1A);
  }

  .logo-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo-section .image-section {
    width: 5rem;
    margin-left: 0.625rem;
  }

  .header-section {
    display: none;
  }

  .navTrigger {
    display: block;
    cursor: pointer;
  }

  .navTrigger {
    cursor: pointer;
    width: 1.5625rem;
    height: 1.1875rem;
  }

  .navTrigger i {
    background-color: var(--black-color);
    border-radius: 0.125rem;
    content: "";
    display: block;
    width: 100%;
    height: 0.1875rem;
    margin-bottom: 0.25rem;
  }

  .mobile-header-section .icons-section {
    gap: 0.625rem;
  }

  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--white-color);
    width: 25%;
    height: 100%;
    z-index: 4;
    padding: 1.25rem 1.25rem 0.625rem 1.25rem;
    border-bottom: 0.0625rem solid var(--border-grey-color);
    overflow: auto;
    animation: slideFromLeft 0.3s ease;
    display: none;
  }

  @keyframes slideFromLeft {
    from {
      left: -80%;
    }

    to {
      left: 0;
    }
  }

  .sidebar ul {
    margin: 0;
    padding: 0;
  }

  .sidebar ul li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }

  .categories-section ul li {
    list-style: none;
    margin: 1cap 0;
  }

  .sidebar ul li {
    list-style: none;
    margin: 1.5rem 0;
  }

  .categories-section ul li:first-child {
    margin-top: 0;
  }

  .categories-section ul li:last-child {
    margin-bottom: 0;
  }

  .categories-section ul {
    padding: 0;
    margin: 0;
  }

  .categories-section ul li a {
    color: var(--secondary-text-color);
    text-decoration: none;
    cursor: pointer;
  }

  .categories-section ul li img {
    transform: rotate(-90deg);
  }

  .categories-section ul li a:hover {
    color: var(--primary-text-color);
  }

  .categories-section ul li a:active {
    color: var(--primary-text-color);
  }

  .mobile-button-section .form-control {
    border-radius: 3.75rem;
    font-size: 0.875rem;
    padding: 0.625rem;
  }

  .sidebar .back h2,
  .list-dropdown-section .back h2 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--primary-text-color);
    text-decoration: none;
  }

  .sidebar .back img,
  .list-dropdown-section .back img {
    transform: rotate(90deg);
    margin-right: 0.625rem;
  }

  .sidenav-overlay {
    background: var(--black-color-00);
    opacity: 0.8;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: none;
  }

  .list-dropdown-section {
    padding: 1.25rem;
    position: absolute;
    left: 0;
    top: 0;
    background-color: var(--white-color);
    z-index: 2;
    width: 100%;
    height: 100%;
    animation: slideFromLeft 0.3s ease;
    display: none;
  }

  @keyframes slideFromLeft {
    from {
      left: -80%;
    }

    to {
      left: 0;
    }
  }

  .list-dropdown-section ul {
    margin: 0;
    padding: 0;
  }

  .list-dropdown-section ul li {
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    margin: 1.25rem 0;
  }

  .list-dropdown-section ul li a {
    margin: 0.625rem 0;
    white-space: nowrap;
  }

  .list-dropdown-section {
    display: none;
  }

  .list-dropdown-section.show {
    display: block;
  }

  .filter-section {
    padding: 0 0 0 0.3125rem !important;
  }

  .webinar-details-section .row {
    justify-content: space-between;
  }

  .webinar-details-section .right-section {
    margin: 0;
  }

  .top-courses-section {
    padding: 0 1.25rem 6.25rem;
  }

  ul .menu li {
    margin: 0px 0px !important;
  }

  .courses {
    justify-content: center;
  }

  .icons-section img {
    cursor: pointer;
  }

  .about-page-section .box {
    justify-content: center;
  }

  .course-content-tab {
    padding: 5rem 2.5rem 8.125rem 2.5rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .mobile-button-section {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .mt-mb-150 {
    margin: 4rem 0;
  }

  .auth-page {
    flex-direction: column;
  }

  .auth-page .left-section {
    width: 100%;
    height: 5.525rem;
  }

  .auth-page .left-section .logo {
    width: 6.25rem;
    height: 2.5rem;
    top: 1.6rem;
    left: 1rem;
  }

  .auth-page .left-section .image-section {
    display: none;
  }

  .auth-page .right-section {
    width: 100%;
    max-height: calc(100vh - 6.25rem);
    padding: 1rem;
  }

  .course-detail-page .banner-section {
    width: 100%;
    margin-right: 0;
    padding: 2.5rem 0.75rem;
  }

  .tab-section.course-detail-tab {
    width: 100%;
    margin: 0;
    padding: 0 12px;
  }

  .course-detail-content {
    flex-direction: column-reverse;
  }

  .course-card-section {
    position: relative;
    margin-top: 0;
    top: 0;
    right: 0;
    margin: 1.25rem auto;
    width: fit-content;
  }

  .tab-section .nav-tabs .nav-link {
    width: 50%;
  }

  .nav-tabs::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .slider-section .left-section h2 {
    font-size: 1.375rem;
    text-align: center;
  }

  .slider-section .left-section span {
    font-size: 2.75rem;
  }

  .slider-section .left-section .btn-section {
    text-align: center;
  }

  .input .login-btn {
    margin-right: 0;
  }

  .copyright-section {
    padding: 1.875rem 0;
  }

  .header {
    padding: 0.9375rem 0;
  }

  .courses-section {
    padding: 2rem 0;
  }

  .courses .course-name {
    width: 40%;
  }

  .course-name {
    margin-bottom: 2.5rem;
  }

  .courses {
    justify-content: center;
    margin-bottom: 3.75rem;
  }

  .development-section,
  .personal-development {
    padding: 0;
  }

  .blogs-section {
    padding: 0;
  }

  .blogs-card {
    margin-right: 1.25rem;
  }

  .rating-section {
    padding: 0;
  }

  .footer-section {
    padding: 40px 0.75rem;
  }
  .footer-section .footer-container {
    padding: 0;
  }

  .details-section p {
    width: 100%;
  }

  .social-profiles-section ul li a {
    width: 2.875rem;
    height: 2.75rem;
  }

  .categories-section ul li a {
    font-size: 1.375rem;
  }

  .sidebar .signup-btn {
    font-size: 1.375rem !important;
  }

  .sidebar .login-btn {
    font-size: 1.375rem !important;
  }

  .categories-section ul li {
    list-style: none;
    margin: 2.5rem 0;
  }

  .sidebar ul li {
    list-style: none;
    margin: 2.5rem 0;
  }

  .icons-section .login-btn {
    display: none;
  }

  .icons-section .signup-btn {
    display: none;
  }

  .sidebar .signup-btn {
    white-space: nowrap;
    border: none;
    background-color: transparent;
    color: var(--primary-text-color);
    padding: 0;
  }

  .sidebar .login-btn {
    white-space: nowrap;
    border: none;
    background-color: transparent;
    color: var(--primary-text-color);
    padding: 0;
    background-color: #7950e2;
  }

  .mobile-button-section {
    display: block;
  }

  .sidebar {
    width: 80% !important;
  }

  .top-courses-section {
    margin-top: 2.5rem;
  }

  .filter-section {
    padding: 0 !important;
  }

  .development-section-padding {
    padding: 0 !important;
  }

  .speaker-details-section {
    flex-wrap: wrap;
  }

  .speaker-info-section {
    margin-top: 1.25rem;
    margin-left: 0;
  }

  .webinar-card-footer .icon-section .icon {
    padding: 0.5rem;
  }

  .blog_listing_page {
    margin: 4rem 0;
  }

  .blog_listing_detail .left-section {
    padding-left: 0rem;
  }

  .blog_listing_detail .blog_listing_title .border {
    min-width: 100%;
  }

  .blog_listing_detail .blog_listing_title_img {
    height: 18rem;
  }

  .blog_listing_detail .left-section {
    padding-right: 0;
    padding-bottom: 2rem;
  }

  .blog_listing_detail .left-section .social-media-icon-section {
    flex-wrap: wrap;
  }

  tbody h5 {
    width: 100%;
  }

  .my-profile-section {
    justify-content: center;
  }

  .sorting-section {
    padding: 0 1.25rem !important;
    margin-top: 30px;
  }

  .about-section {
    margin-top: 1.25rem;
  }

  .browse-section,
  .purchase-section,
  .learning-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .browse-section:after {
    display: none;
  }

  .purchase-section {
    margin-top: 2.5rem;
  }

  .learning-section {
    margin-top: 2.5rem;
  }

  .purchase-section:after {
    display: none;
  }

  .contact_us_container {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }

  .contact_us .contact-section {
    margin-bottom: 2rem;
  }

  .send-message-box {
    padding: 1rem;
  }

  .faq-page {
    margin: 3rem 0 4rem;
  }

  .free-resources-page {
    margin: 3rem 0 4rem;
  }

  .free-resources-page .practice-resources-card-section {
    margin-top: 2rem;
  }

  .course-preview-dialog .course-content .course-modal-body {
    flex-wrap: wrap;
  }

  .course-preview-dialog
    .course-content
    .course-modal-body
    .modal-left-section {
    width: 100%;
  }

  .faq-accordion .faq-accordion-item {
    padding: 1rem;
  }

  .faq-accordion-item .faq-accordion-item-desc {
    margin: 1.25rem 0;
  }

  .course-preview-dialog
    .course-content
    .course-modal-body
    .modal-right-section {
    margin: 1.5rem 0;
    width: 100%;
    padding: 0 1rem;
  }

  .course-preview-dialog
    .course-content
    .course-modal-body
    .modal-left-section {
    padding: 1rem;
  }

  .mt-150 {
    margin-top: 1.25rem !important;
  }

  .mb-150 {
    margin-bottom: 1.25rem !important;
  }

  .courses-details .right-section {
    padding-top: 1.125rem;
  }

  .our-blogs-btn .carousel-control-prev {
    right: -1%;
  }

  .our-blogs-btn .carousel-control-next {
    right: -1%;
  }

  .course-padding-bottom {
    padding: 0 1.25rem 0;
  }

  .custom-accordion {
    margin-bottom: 0;
  }

  .instructor-section .instructor-description {
    margin-bottom: 0;
  }

  .webinar-detail-image-section {
    margin-top: 2.5rem !important;
  }

  .mt-80 {
    margin-top: 1.25rem;
  }

  .cart-listing-section .cart-listing-table th:first-child {
    padding: 1.25rem 0 1.25rem 1.25rem;
  }

  .cart-listing-section .cart-listing-table th:last-child {
    padding: 1.25rem 1.25rem 1.25rem 0;
  }

  .cart-listing-section .cart-listing-table td:first-child {
    padding: 1.25rem 0 1.25rem 1.25rem;
  }

  .cart-listing-section .cart-listing-table td:last-child {
    padding: 1.25rem 1.25rem 1.25rem 0;
  }

  .footer-email {
    min-width: 180px;
  }

  .blog_listing_page .left-section {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .slider-section .left-section h2 {
    font-size: 22px;
  }

  .slider-section .left-section span {
    font-size: 28px;
  }

  .btn-section .join-now-btn {
    padding: 0.625rem;
  }

  .btn-section .free-courses-btn {
    padding: 0.625rem;
  }

  .courses-section {
    padding: 1rem 0;
  }

  .courses {
    margin-bottom: 0;
  }

  .last-card {
    margin-right: 1.875rem;
  }

  /* .slider {
        overflow-x: auto;
        overflow-y: hidden;
    } */

  .slider::-webkit-scrollbar {
    display: none;
  }

  .development-card {
    margin-right: 1.25rem;
  }

  .details-section p {
    width: 100%;
  }

  .copyright-section .container {
    justify-content: center !important;
  }

  .copyright-section .container p {
    margin-bottom: 0.625rem;
  }

  .social-profiles-section {
    margin-top: 1.25rem;
  }

  .social-profiles-section ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .filter-section {
    padding: 0 1.25rem !important;
  }

  .sorting-section {
    padding: 0 12px !important;
  }

  .filter-section {
    padding: 0 12px !important;
  }

  .sorting-section .right-section {
    margin-top: 1.875rem;
  }

  .edit-profile-section,
  .change-password-section {
    padding: 2.5rem 0.625rem 0 0.625rem !important;
  }

  .my-profile-section {
    margin-bottom: 5rem;
  }

  .change-password {
    display: flex;
    justify-content: center;
  }

  .input-field-flex {
    flex-wrap: wrap;
  }

  .my-profile-section .left-section {
    padding: 1.25rem 0 1.5625rem 0;
    border-right: 0;
    border-bottom: 0.0625rem solid var(--border-grey-color);
  }

  .custom-accordion .accordion-item .accordion-header .accordion-button {
    padding: 1.125rem 0rem 1rem 1.8rem !important;
  }

  .custom-accordion .accordion-item .accordion-header .accordion-button::after {
    left: 0;
  }

  .custom-accordion
    .accordion-item
    .accordion-header
    .accordion-button
    .header-icon {
    padding-right: 0;
  }

  .course-content-tab {
    padding: 5rem 2.5rem 0.125rem 2.5rem;
  }

  .free-resources-page .sort {
    justify-content: start;
  }

  .free-resources-page .search-section .right-section {
    justify-content: start;
    margin-top: 1.25rem;
  }

  .about:nth-child(n-1) {
    margin-top: 1.25rem;
  }

  .about {
    width: 41% !important;
  }

  .about-page-section .col-lg-7 .text-section {
    margin-left: 0;
    margin-top: 1.25rem;
  }

  .mb-100 {
    margin-bottom: 1.25rem !important;
  }

  .mb-150 {
    margin-bottom: 1.875rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .mt-mb-150 {
    margin: 2rem 0;
  }

  .left-section h2 {
    margin-top: 1.25rem;
    text-align: center;
  }

  .slider-section .btn-section {
    display: flex;
    justify-content: center;
  }

  .slider-section .left-section {
    justify-content: center;
    margin-left: 0;
  }

  .our-blogs-section {
    padding: 0 0 3.125rem;
  }

  .btn-section .join-now-btn {
    padding: 0.875rem;
    font-size: 0.875rem;
  }

  .btn-section .free-courses-btn {
    padding: 0.875rem;
    font-size: 0.875rem;
  }

  .courses .course-name {
    width: 70%;
  }

  .courses .course-name {
    margin-right: 0;
  }

  .courses-details .right-section {
    padding: 3.125rem 0.625rem 0 0.625rem;
  }

  .development-section {
    margin-bottom: 2.5rem;
  }

  .development-card {
    margin-right: 0;
  }

  .blogs-card {
    margin-right: 0;
  }

  .ratings-card {
    margin-right: 0;
    padding: 1.25rem 0.625rem;
  }

  .top-courses-section {
    padding: 0 0 3.125rem;
  }

  .ratings-section .card p {
    padding: 1.875rem 0.9375rem 0;
  }

  .ratings-section .card-body {
    padding: 0 0.9375rem 1.875rem;
  }

  .slider-section .carousel-control-prev {
    width: 1.875rem;
    height: 1.875rem;
  }

  .slider-section .carousel-control-next {
    width: 1.875rem;
    height: 1.875rem;
  }

  .slider-btn .carousel-control-prev {
    width: 1.875rem;
    height: 1.875rem;
    top: 23%;
  }

  .slider-btn .carousel-control-next {
    width: 1.875rem;
    height: 1.875rem;
    right: -2%;
    top: 23%;
  }

  .details-section {
    margin: 1.25rem 0 2.5rem 0;
  }

  .details-section img {
    display: flex;
    margin: 0 auto;
  }

  .details-section p {
    text-align: center;
  }

  .footer-section {
    padding: 1.5625rem 0.75rem;
  }

  .links-section {
    margin-bottom: 1.25rem;
  }

  .links-section ul li a {
    text-align: center;
  }

  .copyright-section {
    padding: 1.5625rem 0.75rem;
  }

  .social-profiles-section ul {
    justify-content: center;
    flex-wrap: wrap;
  }

  .social-profiles-section ul li h3 {
    text-align: center;
    margin-bottom: 1.625rem;
  }

  .copyright-section .container {
    padding: 0;
  }

  .copyright-section .container p {
    margin-bottom: 1.25rem;
  }

  .categories-section ul li a {
    font-size: 1rem;
  }

  .sidebar .signup-btn {
    font-size: 1rem !important;
  }

  .sidebar .login-btn {
    font-size: 1rem !important;
  }

  .categories-section ul li {
    list-style: none;
    margin: 1.375rem 0;
  }

  .sidebar ul li {
    list-style: none;
    margin: 1.5rem 0;
  }

  .pagination {
    margin: 0 !important;
  }

  .page-link {
    padding: 0.625rem !important;
  }

  .development-section-padding-bottom {
    padding-bottom: 0.5rem !important;
  }

  .webinar-card {
    flex-direction: column !important;
  }

  .horizontal-card {
    padding-top: 1.25rem !important;
    margin-left: 0;
  }

  .card-margin-bottom {
    margin-bottom: 2.5rem;
  }

  .row-gap {
    row-gap: 0.3125rem;
  }

  .card-hover-section {
    display: none !important;
  }

  .card-hover-section::before {
    display: none;
  }

  .webinar-detail-image-section {
    margin-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .webinar-details-section .right-section {
    margin-bottom: 2.5rem;
  }

  .speaker-details-section {
    justify-content: center;
  }

  .speaker-details {
    flex-direction: column;
  }

  .course-rating-section {
    margin-right: 0;
  }

  .webinar-details-section .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .blog_listing_page {
    margin: 2rem 0;
  }

  .blog_listing_page .blog-card-section {
    margin-bottom: 1rem;
  }

  /* .profile-section{
        display: none;
    } */

  .cart-section .image-section,
  .wishlist-section .image-section {
    width: 15.625rem;
    height: 100%;
  }

  .cart-listing-section {
    margin-top: 1.25rem;
  }

  .payment-failed-section {
    margin: 1.25rem 0.625rem;
  }

  .order {
    margin-right: 0;
  }

  .order-date-section {
    justify-content: center !important;
  }

  .order-date-section .right-section {
    margin-top: 0.625rem;
  }

  .payment-info-section {
    margin-left: 0;
  }

  .order-detail-section {
    margin-top: 1.25rem;
  }

  .cart-section h2,
  .wishlist-section h2 {
    font-size: 2rem !important;
  }

  .order-history-section {
    margin-top: 1.25rem;
  }

  .about-page-section .text-section h1 {
    margin-top: 1.25rem !important;
  }

  .text-align-center {
    text-align: center;
  }

  .about {
    margin-bottom: 1.25rem;
    width: 80% !important;
  }

  .mb-100 {
    margin-bottom: 3.125rem !important;
  }

  .mb-150 {
    margin-bottom: 1.25rem !important;
  }

  .pt-100 {
    padding-top: 3.125rem !important;
  }

  .pb-150 {
    padding-bottom: 3.125rem !important;
  }

  .instructor-card {
    margin-top: 1.875rem;
  }

  .my-webinar-nav-link {
    width: 100% !important;
  }

  .contact_us_container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .course-content-tab {
    padding: 0.625rem;
  }

  .course-content {
    padding: 0.625rem;
  }

  .filter-margin {
    margin-top: 1.25rem;
  }

  .faq-page {
    margin: 2rem;
  }

  .free-resources-page {
    margin: 2rem;
  }

  .course-preview-dialog {
    max-width: 95% !important;
  }

  .free-resources-page .search-section .right-section .heading .sort-by {
    margin-bottom: 1.25rem;
  }

  .recorded-session-section .accordion-header {
    padding: 0.625rem;
  }

  .sort-heading {
    margin-top: 1.25rem;
  }

  .instructor-justify-center {
    justify-content: center !important;
  }

  .slick-prev {
    left: -0.5625rem !important;
    width: 1.875rem !important;
    height: 1.875rem !important;
  }

  .slick-next {
    right: -0.5625rem !important;
    width: 1.875rem !important;
    height: 1.875rem !important;
  }

  .course-listing .col-lg-4:nth-child(n + 4) {
    margin-top: 0;
  }

  .card-margin-bottom {
    margin-bottom: 0;
  }

  .recommended-sort {
    margin-top: 1.25rem;
    text-align: center;
  }

  .course-padding-bottom {
    padding: 0;
  }

  .tab-section.course-detail-tab {
    padding: 0 0;
  }

  .course-detail-page .banner-section {
    padding: 2.5rem 0;
  }

  .speaker-details-section {
    padding: 1rem;
  }

  .order-history-section .search-section .col-lg-5 {
    justify-content: start;
  }
}

@media screen and (max-width: 1726px) {
  .hover-section ul li {
    min-width: 200px;
  }
}

@media screen and (min-width: 1940px) {
  .development-section {
    clip-path: inset(-8vw -0.7vw -5vw -0.7vw);
  }
}
/* responsive design css end */

.photoDetails {
  display: flex;
}

.uploadPgotobox h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.24px;
  text-align: left;
  color: #2d2f31;
}

.uploadImagebox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  margin-left: 10px;
}

.uploadImagebox h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.24px;
  text-align: left;
}

.teacherPhoto {
  width: 100px;
  height: 100px;
  position: relative;
}

.userimage {
  border-radius: 50%;
  overflow: auto;
}

#fileInput-error {
  position: absolute;
  bottom: -15px;
  left: 0;
  width: max-content;
}

.uploadImage h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.24px;
  text-align: left;
  color: #7950e2;
  cursor: pointer;
}

.teacherPhoto img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.userimage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #7950e21a;
}

.uploadImage {
  display: flex;
  align-items: start;
  gap: 5px;
}

#crossiconbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #2d2f31;
  width: fit-content;
  height: fit-content;
  padding: 4px;
  border-radius: 50%;
  transform: translate(-22px, 3px);
  border: 1px solid #e4e8eb;
}

.drop-zone {
  max-width: 560px;
  height: 70px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: #cccccc;
  border: 1px dashed #e4e8eb;
  margin: 10px 0px;
  position: relative;
}

.drop-zone #selfIntroVideo-error {
  position: absolute;
  bottom: -45px;
  left: 0;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  width: 0px;
  height: 0px;
  outline: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.browesBox {
  margin: 20px 0px;
}

.browesBox .videoLimit {
  margin-bottom: 20px;
}

.browesBox h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.71px;
  text-align: left;
  color: #939393;
}

.browesBox h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.24px;
  text-align: left;
  color: #2d2f31;
}

.drop-zone .browseText {
  color: #7950e2;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.71px;
  text-align: left;
}

.drop-zone__prompt img {
  margin-right: 10px;
}

.teacherBio h5 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.24px;
  text-align: left;
  color: #2d2f31;
  margin-bottom: 6px;
}

.bioTextBox {
  outline: none;
  padding: 16px;
  height: 110px;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
}

.bioTextBox::placeholder {
  color: #939393;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 400;
}

.mediumBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.mbtns {
  width: 177px;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.3px;
  text-align: center;
  color: #939393;
  background-color: white;
  border: 1px solid #e4e8eb;
}

.medium h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.24px;
  color: #2d2f31;
  margin: 0px;
}

/* .medium {
    margin-bottom: 30px;
} */

.auth-wrapper {
  width: 560px;
}

.eduBox h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.24px;
  text-align: left;
  color: #2d2f31;
}

.instBox {
  margin: 20px 0px;
}

.instBox h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 20.24px;
  text-align: left;
  color: #2d2f31;
}

.groupCoachingbox {
  border: 1px solid #e4e8eb;
  padding: 20px;
}

.groupCoachingbox h6 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17.71px;
  text-align: left;
  color: #939393;
}

.coachingBtn .groupBtn {
  background-color: #f5f5f5;
  width: fit-content;
  height: auto;
  border-radius: 20px !important;
  padding: 7px 20px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #939393;
}

.partyBtn {
  background: #7950e2 !important;
  color: #fff !important;
}

.shareExperiance {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.experianceBox3,
.experianceBox4 {
  display: flex;
  gap: 20px;
}

.experianceBox3 .box3-1,
.box3-2 {
  width: 50%;
  height: auto;
  padding: 15px;
  border: 1px solid #e4e8eb;
}

.experianceBox4 .box4-1 {
  width: 37%;
  height: auto;
  padding: 15px;
  border: 1px solid #e4e8eb;
}

.experianceBox4 .box4-2 {
  width: 71%;
  height: auto;
  padding: 15px;
  border: 1px solid #e4e8eb;
}

.experianceBox5 {
  width: 100%;
  height: auto;
  padding: 15px;
  border: 1px solid #e4e8eb;
}

.termscheckBox {
  position: relative;
  margin-bottom: 20px;
}

#checkbox-error {
  position: absolute;
  bottom: -15px;
  left: 0;
}

.terms {
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #2d2f31;
}

.terms a {
  text-decoration: underline;
  color: #7950e2 !important;
}

.termscheckBox input {
  width: 24px;
  height: 24px;
}

.fileDetails {
  display: flex;
  gap: 10px;
  width: fit-content;
  margin: 11px 0px;
  background: #f5f5f5;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.fileDetails p {
  margin: 0px;
  margin: 3px 0px;
}

.fileDetails .fileName {
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.71px;
  text-align: left;
  color: #2d2f31;
}

.fileDetails .fileSize {
  font-family: Be Vietnam Pro;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.65px;
  text-align: left;
  color: #939393;
}

label#contact_number-error {
  transform: translateX(-89px);
}

.widget-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

/* .widget-title:hover .menu-os_category_music-container{
    display: none;
} */

#next-step-5 {
  margin-top: 10px;
}

.menu {
  margin: 0px !important;
  border-bottom: none !important;
}

.menu li {
  margin: 14px 0px !important;
}

.teacherFinalApprove {
  /* background-image: url(../img/finalBGimg.svg); */
  width: 100%;
  height: 100vh;
  padding: 0px 140px;
  background-size: cover;
  background-repeat: no-repeat;
}

.approveBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.infoLine {
  width: 460px;
  text-align: center;
  margin: 30px 0px;
  font-size: 36px;
  font-weight: 600;
  line-height: 45.54px;
}

.letsVisiteBtn {
  width: 233px;
  height: 50px;
  padding: 15px 30px 15px 30px;
  background-color: #7950e2;
  color: #fff !important;
  text-align: center;
}

.quickLinktitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 14px;
}

#menu-footer-quick-links li {
  margin: 0px !important;
}

.wp-block-image {
  margin: 0px !important;
}

.details-section p {
  width: fit-content;
  font-size: 18px;
  line-height: 30px;
  color: #939393;
}

#menu-footer-copyright-menu ul li a::after {
  content: "" !important;
  position: absolute !important;
  bottom: 0.125rem !important;
  width: 0.0625rem !important;
  height: 1rem !important;
  background-color: var(--white-color) !important;
  margin-left: 0.5625rem !important;
}

#menu-footer-copyright-menu {
  display: flex !important;
}

.education {
  max-width: 560px;
  width: 560px;
}

.letsExploreBtn {
  margin-top: 30px;
  width: 560px;
}

.dropdown:hover ul.dropdown-menu {
  display: block;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate(0px, 46px);
}

.cartDetails .cartThumbnail {
  width: 80px;
  height: 60px;
}

.loading-spinner {
  border: 2px solid #f3f3f3;
  /* Light grey */
  border-top: 2px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-lp-course-view-cart {
  background: #ffb606;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 9px 24px;
}

.wishListIconRed {
  display: none;
}

#myImage a img {
  width: 10rem !important;
  height: 7.5rem !important;
}

.cart_coupon_status {
  width: 100%;
  border: 1px dashed #e4e8eb;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
}

.category-box li {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px !important;
}

.category-box li a {
  color: #939393;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
}

.category-box li a:hover {
  color: var(--primary-text-color);
}

.recent-posts-section li {
  display: flex;
}

.recent-posts-section li a {
  color: #2d2f31;
}

.recent-posts-section {
  margin-bottom: 40px;
}

.category-box {
  margin-bottom: 40px !important;
}

.recent-posts-section li:hover a {
  color: #7950e2;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.googleBtn {
  background-color: #ffffff !important;
}

.post_count {
  color: #939393 !important;
}

.card-text h4 a {
  color: #2d2f31 !important;
}

.card-text h4 a:hover {
  color: #7950e2 !important;
}

.left-section p {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 26px !important;
  /* color: #939393 !important; */
}

.verify_input {
  width: 3.75rem;
  height: 3.75rem;
  margin: 1.875rem 1.875rem 1.875rem 0;
}

.verify_input .input-field {
  padding: 1rem !important;
  width: 3.75rem;
  height: 3.75rem;
  text-align: center;
}

.verify_input:first-child {
  margin-left: 1.5625rem;
}

.verify_input:last-child {
  margin-right: 1.5625rem;
}

span.numberOfcartItem {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 10px;
  top: -10px;
  background-color: var(--purple-color) !important;
  color: #fff;
  padding: 5px;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  font-size: 10px;
}

.form-style button {
  position: relative;
}

.button--loading:after {
  content: " ";
  width: 0.75em;
  height: 0.75em;
  margin-right: 0.5em;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: loading-spinner-animation 1s linear infinite;
  display: inline-block;
  font-size: 30px;
  position: absolute;
  right: 32%;
  top: 13px;
}

@keyframes loading-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-custom-message {
  color: red;
  font-size: 1.25rem;
  text-align: center;
  margin: 1.875rem 0rem;
}

.signup_error_message {
  color: red;
  font-size: 1.25rem;
  text-align: center;
  margin: 1.875rem 0rem;
}

.teacher-educational .drop-zone {
  position: relative;
}

.drop-zone #achivement-error {
  position: absolute;
  bottom: -20px;
  left: 0;
}

.drop-zone #certificate-error {
  position: absolute;
  bottom: -20px;
  left: 0;
}

.allCourses {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}

#crossIcon {
  /* display: block; */
  width: 15px;
}

.mbtns {
  margin: 5px;
  padding: 10px;
  border: 1px solid #e4e8eb;
  cursor: pointer;
  color: #939393;
  background-color: #ffffff;
}

/* 
.selected {
    background-color: yellow;
    color: red;
    border-color: blue;
} */

/* Wishlist CSS */
.heart-btn {
  position: relative;
}

.heart-btn.button--loading svg {
  visibility: hidden;
}

.heart-btn.button--loading:after {
  border: 3px solid #8d00e5;
  border-color: #8d00e5 transparent #8d00e5 transparent;
  right: 0px;
}

.heart-btn.button--loading.active:after {
  border: 3px solid #ffff;
  border-color: #fff transparent #fff transparent;
}

.wc-block-checkout__actions_row .components-button {
  background-color: #7950e2;
  color: #ffffff;
}

.wc-block-checkout__form #billing #billing-first_name,
#billing-last_name,
#billing-address_1,
#billing-address_2,
#components-form-token-input-0,
#billing-city,
#components-form-token-input-1,
#billing-postcode,
#billing-phone,
#email {
  padding-left: 20px;
  border: 0.0625rem solid var(--border-grey-color) !important;
  border-radius: 0 !important;
  color: var(--primary-text-color) !important;
  box-shadow: 0rem 0.0625rem 0.125rem 0rem var(--black-color-0D);
  font-size: 1.125rem !important;
  font-weight: 400 !important;
  line-height: 1.1069rem !important;
  width: 100% !important;
  outline: none;
  box-shadow: unset;
}

#payment-method .wc-block-components-radio-control-accordion-option {
  box-shadow: none;
  border: 1px solid #e4e8eb;
}

.review-stars-rated {
  margin: 0px !important;
}

.coupon-section .couponSuccess {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px dashed #e4e8eb;
}

.remove-coupon-btn {
  cursor: pointer;
}

#copy-alert {
  position: absolute;
  display: none;
  color: white;
  margin-top: 10px;
  bottom: -50%;
  left: 25%;
  background: black;
  color: wheat;
  padding: 6px;
  border-radius: 7px;
  font-size: 10px;
}

.instructor-page .instructor-card {
  padding: 1.875rem 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.0625rem solid var(--border-grey-color);
  margin-bottom: 2.5rem;
  flex-wrap: wrap;
}

.instructor-page .instructor-card .instructor-left-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.instructor-page .instructor-card .instructor-left-section .instructor-logo {
  width: 9.375rem;
  height: 9.375rem;
  border-radius: 100%;
  margin-right: 1.5rem;
}

.instructor-page
  .instructor-card
  .instructor-left-section
  .instructor-logo
  img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.instructor-page .instructor-card .instructor-left-section .instructor-heading {
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.5rem;
  color: var(--black-color-00);
}

.copy-link {
  justify-content: end;
}

.copy-link-div {
  padding: 0.75rem 1rem !important;
  color: var(--primary-text-color) !important;
  line-height: 1.25rem !important;
}

#popup-course {
  position: static !important;
}

.stars-rating {
  line-height: 32px;
  font-size: 2.35em;
}

.stars-rating .fa-star {
  color: #f9931d !important;
}

.stars-rating .fa-star-o {
  color: #000 !important;
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  top: 1px;
  color: #ffffff;
}

.accordion-checkbox {
  display: none;
}

.courseTitle {
  cursor: pointer;
}

.subtopics {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.accordion-checkbox:checked + .courseTitle + .subtopics {
  max-height: 100vh;
}

.accordion-checkbox:checked + .courseTitle {
  background-color: #7950e21a;
}

.courseTitle svg {
  transition: transform 0.5s ease;
}

.accordion-checkbox:checked + .courseTitle svg {
  transform: rotate(180deg);
}

/* Add your custom styles here */
.lectureVideo {
  position: relative;
}

.controls-section {
  position: absolute;
  bottom: 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.progress-bar-section {
  display: flex;
  align-items: center;
}

.complete-border {
  width: 100%;
  background: #ccc;
}

.ratings-border {
  background: #f00;
  height: 5px;
}

.video-play-section img {
  cursor: pointer;
}

#muteContainer {
  position: relative;
  padding-right: 6px;
}

#volumeBar {
  width: 100px;
  height: 7px;
  background-color: #ccc;
}

#muteContainer:hover .volumebox {
  display: flex;
  justify-content: center;
  align-items: center;
}

#volumeBar:hover {
  display: block;
}

#volumeProgress {
  background-color: #7950e2;
  height: 100%;
}

.volumebox {
  display: none;
  position: absolute;
  top: 15%;
  left: 35px;
  width: 100px;
  height: 20px;
}

/* woocommerce checkout */

.woocommerce-checkout .wp-block-woocommerce-checkout {
  max-width: 82.5rem;
  margin: 0 auto;
}

.woocommerce-checkout
  .wc-block-components-form
  .wc-block-checkout__contact-fields
  .screen-reader-text {
  width: unset !important;
}

.woocommerce-checkout
  .wc-block-components-form
  .wc-block-checkout__contact-fields
  .wc-block-components-checkout-step__container {
  margin: 0 0 0.625rem;
  color: #939393;
  font-weight: 400;
}

.woocommerce-checkout
  .wc-block-components-checkout-step--with-step-number
  .wc-block-components-checkout-step__container:after {
  height: calc(100% + 1.5625rem);
}

.woocommerce-checkout .wc-block-checkout__add-note {
  margin-left: 2rem;
}

.woocommerce-checkout .wc-block-components-checkbox label {
  padding-left: 0;
}

.woocommerce-checkout
  .wc-block-components-checkbox
  .wc-block-components-checkbox__input[type="checkbox"]:checked {
  background-color: #7950e2;
}

.woocommerce-checkout
  .wc-block-components-checkbox
  .wc-block-components-checkbox__mark {
  fill: #fff;
  height: 1.125em;
}

.woocommerce-checkout .wc-block-checkout__terms span {
  color: #939393;
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 2.2375rem;
}

.woocommerce-checkout
  .wc-block-checkout__add-note
  .wc-block-components-textarea:focus {
  box-shadow: unset !important;
}

.woocommerce-checkout .components-button {
  background-color: #7950e2;
  color: #fff;
  border: none;
  outline: none;
  padding: 0.9375rem 1.25rem;
}

.woocommerce-checkout input {
  outline: none !important;
}

.woocommerce-checkout .wc-block-components-totals-item__label {
  font-weight: 700 !important;
}

.woocommerce-checkout .wc-block-components-formatted-money-amount {
  font-weight: 700 !important;
}

.woocommerce-checkout .wc-block-components-panel__button {
  font-size: 1.25rem;
  font-weight: 500;
}

.woocommerce-checkout
  .wc-block-components-order-summary
  .wc-block-components-order-summary-item__image {
  margin-top: 0;
}

.woocommerce-checkout
  .wc-block-components-order-summary
  .wc-block-components-order-summary-item__image
  img {
  border: 0.0625rem solid #e4e8eb !important;
}

.woocommerce-checkout
  .wc-block-components-order-summary
  .wc-block-components-order-summary-item__quantity
  span {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.woocommerce-checkout
  .wc-block-components-chip
  .wc-block-components-chip__text {
  font-size: 0.75rem;
}

.woocommerce-checkout
  .wc-block-components-chip
  .wc-block-components-chip__remove {
  background-color: transparent;
  height: 20px !important;
}

.woocommerce-checkout
  .wc-block-components-chip.wc-block-components-chip--radius-large {
  border: 0.0625rem solid #e4e8eb !important;
}

.woocommerce-checkout
  .wc-block-components-chip__remove:hover
  .wc-block-components-chip__remove-icon {
  fill: #1e1e1e;
}

/* Woocommerce order payment screen design */

.woocommerce-order-pay .woocommerce {
  max-width: 82.5rem;
  margin: 0 auto;
  padding: 3.125rem 1rem;
}

.woocommerce-order-pay .shop_table {
  max-width: 82.5rem;
  border: 0.0625rem solid #d3d3d3;
}

.woocommerce-order-pay .shop_table thead tr th {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #000;
  border-bottom: 0.0625rem solid #d3d3d3;
}

.woocommerce-order-pay .shop_table tbody tr td {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #000;
  border-bottom: 0.0625rem solid #d3d3d3;
}

.woocommerce-order-pay .shop_table tfoot tr td {
  font-weight: 700 !important;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-bottom: 0.0625rem solid #d3d3d3;
}

.woocommerce-order-pay .shop_table tfoot tr td.product-quantity strong {
  font-weight: 400 !important;
}

.woocommerce-order-pay .shop_table tfoot tr th {
  font-weight: 700 !important;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  border-bottom: 0.0625rem solid #d3d3d3;
}

.woocommerce-order-pay .wc_payment_methods {
  list-style: none;
  margin: 0;
  padding: 0;
}

.woocommerce-order-pay
  .wc_payment_methods
  .payment_method_cod
  .payment_method_cod {
  color: #000;
  padding-left: 0.5rem;
}

.woocommerce-order-pay .wc_payment_methods .payment_box p {
  color: #939393;
  padding-left: 0.9rem;
  margin-bottom: 0rem !important;
}

.woocommerce-order-pay .woocommerce-privacy-policy-text {
  margin: 1.25rem 0 3rem;
  color: #939393;
  padding-left: 0.9rem;
}

.woocommerce-order-pay .form-row button {
  background-color: #7950e2;
  color: #fff;
  border: none;
  outline: none;
  padding: 0.9375rem 1.25rem;
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .woocommerce-order-pay .header-section {
    display: none;
  }

  .woocommerce-order-pay .mobile-header {
    display: none !important;
  }

  .woocommerce-order-pay .site-footer {
    display: none;
  }

  .woocommerce-order-pay .form-row button {
    width: 100%;
  }
}

.add-to-cart-btn {
  position: relative;
  height: 3.125rem;
}

.buy-now-btn {
  height: 3.125rem;
}

.button--loading:after {
  content: "";
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 68%;
  top: 30%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.range-slider {
  width: 300px;
  text-align: center;
  position: relative;
  z-index: 99999;
}

.rangeValues {
  display: block;
}

input[type="range"] {
  -webkit-appearance: none;
  border: 1px solid white;
  width: 300px;
  position: absolute;
  left: 0;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
  margin-top: -4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}

input[type="range"]::-moz-range-track {
  width: 300px;
  height: 5px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}

/*hide the outline behind the border*/

input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]::-ms-track {
  width: 300px;
  height: 5px;
  /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
  background: transparent;
  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 6px 0;
  /*remove default tick marks*/
  color: transparent;
  z-index: -4;
}

input[type="range"]::-ms-fill-lower {
  background: #777;
  border-radius: 10px;
}

input[type="range"]::-ms-fill-upper {
  background: #ddd;
  border-radius: 10px;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #21c1ff;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #888;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ccc;
}

#selfVideoError {
  margin-left: 0px !important;
}

#fieldset-shipping {
  display: none !important;
}

.form-table #fieldset-billing {
  display: none !important;
}

/* .dokan-left.dokan-order-shipping-address {
    display: none !important;
}

.dokan-panel-body ul.list-unstyled.customer-details {
    display: none !important;
} */

.videoicon {
  display: none;
}

.imageicon {
  display: none;
}

.list-dropdown-section {
  display: none;
}

.list-dropdown-section.active {
  display: block;
}

.add-cart-btn-section {
  position: relative;
}

.button--loading:after {
  content: "";
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 78%;
  top: 30%;
  animation: spin 2s linear infinite;
}
